import { ref } from "vue";
import {
  bulkDelete,
  createContact,
  deleteContact,
  editContact,
  filterContacts,
  getContact,
  getContactLogs,
  getContacts,
  searchContacts,
} from "./queries/contact";
import {
  Contact,
  ContactApiResponse,
  TabFilterItemTypes,
  UserContact,
  createContactType,
} from "./types/contact";
// import { useNumberStore } from "../stores";
import { notify } from "@kyvg/vue3-notification";
import emitter from "./integrations/emitter";
import logger from "./logger";
import { getActiveBusinessId } from "./queries";
import { ContactType } from "./types/callQueue";

export const listContacts = async (link?: string) => {
  try {
    const response = link
      ? await getContacts(getActiveBusinessId(), link)
      : await getContacts(getActiveBusinessId());

    return { contacts: response.data as ContactApiResponse };
  } catch (error: any) {
    notify({
      text: `${error?.message || error}`,
      type: "error",
    });
    throw error;
  }
};

export const getSingleContact = async (contact_id: string) => {
  const contactDetails = ref<UserContact>();
  await getContact(getActiveBusinessId(), contact_id).then(
    (res) => (contactDetails.value = res.data as UserContact)
  );
  return { contact: contactDetails.value };
};

export const handleCreateContact = async (payload: createContactType) => {
  try {
    await createContact(payload, getActiveBusinessId());

    notify({
      text: "Contact created successfully",
      type: "success",
    });
  } catch (error: any) {
    notify({
      text: `${error?.message || error}`,
      type: "error",
    });
  }
};

export const handleEditContacts = async (
  contactId: number,
  payload: ContactType
) => {
  try {
    const requestPayload = {
      ...payload,
      job_title: payload.job_title || null,
    };

    await editContact(contactId, requestPayload, getActiveBusinessId());

    notify({
      text: "Contact updated successfully",
      type: "success",
    });
    window.location.reload();
  } catch (err) {
    notify({
      text: "Error while updating contact",
      type: "error",
    });
  }
};

export const handleSearchContacts = async (text: string) => {
  let contactsList: Contact[] = [];
  let nextLink: string | null = null;

  try {
    const res: { data: ContactApiResponse } = await searchContacts(
      text,
      getActiveBusinessId()
    );
    if (res?.data) {
      contactsList = res.data.data;
      nextLink = res.data.next;
    }
  } catch (error: any) {
    notify({
      type: "error",
      text: error?.message || error?.detail || "An error occured",
    });
    logger.error(error, "Error fetching contacts");
  }

  return { contacts: contactsList, next: nextLink };
};

export const filterContactList = async (
  filterType: TabFilterItemTypes,
  tagsFilter?: boolean
) => {
  const contactsList = ref([]);
  const nextLink = ref<null | string>(null);
  if (tagsFilter) {
    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "-date_created",
      tagsFilter
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  } else if (filterType === "all") {
    await getContacts(getActiveBusinessId()).then((res) => {
      contactsList.value = res.data.data || [];
    });
  } else if (
    filterType === "recently_added" ||
    filterType === "recently_called"
  ) {
    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "-date_created"
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  } else if (filterType === "my_contacts") {
    await filterContacts(
      filterType,
      getActiveBusinessId(),
      "contact_name"
    ).then((res) => {
      contactsList.value = res.data.data || [];
      nextLink.value = res.data.next || null;
    });
  }
  return { contacts: contactsList.value, next: nextLink.value };
};

export const handleDeleteContact = async (id: any) => {
  try {
    await deleteContact(id, getActiveBusinessId());

    notify({
      text: "Contact deleted successfully",
      type: "success",
    });
    setTimeout(() => {
      window.location.reload;
    }, 1000);
  } catch (error) {
    notify({
      text: "Error while deleting contact",
      type: "error",
    });
  }
};

export const handleBulkDelete = async (
  contact_ids: Array<string>,
  deleteAll: boolean = false
): Promise<void> => {
  try {
    await bulkDelete(getActiveBusinessId(), contact_ids, deleteAll);
    notify({
      text: "Contacts deleted successfully",
      type: "success",
    });
    setTimeout(() => {
      // window.location.reload();
      emitter.emit("fetchContacts");
    }, 1000);
  } catch (error: any) {
    notify({
      text: error?.message || error?.detail || "Error while deleting contacts",
      type: "error",
    });
  }
};

export const contactLogs = async (phone_number: any) => {
  const callLogs = ref([]) as any;
  await getContactLogs(getActiveBusinessId(), phone_number).then(
    (res) => (callLogs.value = res.data || [])
  );

  return { contacts: callLogs.value };
};
