import { useAuthStore } from "@/stores/auth.store";
import { useBusinessStore } from "@/stores/businesses.store";
import { useUsersStore } from "@/stores/users.store";
import { notify } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import { encryptToken } from "./encryptionKeys";
import { trackAmplitudeEvent } from "./integrations/analytics/amplitude";
import { AMPLITUDE_EVENTS } from "./integrations/analytics/events";
import logger from "./logger";
import {
  getEncryptionKey,
  getUserDetailsByTokenNewImplementation,
  loginWithEmail,
  requestPasswordResetConfirmation,
  requestResetPaswordEmail,
  sendVerificationEmail,
  updateFeedback,
} from "./queries";
import { fetchTeamMemberDetails } from "./queries/teamMembers";
import { validateMobile } from "./utils";

export const findRecommendedPlan = (
  teamMembers: number,
  isInternational: boolean,
  billingPeriod: string
) => {
  if (isInternational) {
    if (billingPeriod == "Quarterly") {
      return "International (Quarterly)";
    } else if (billingPeriod == "Annually") {
      return "International (Annual)";
    }
    return "International Plan";
  } else {
    if (teamMembers >= 1 && teamMembers <= 3) {
      if (billingPeriod == "Quarterly") {
        return "Standard Plan (Quarterly)";
      }
      if (billingPeriod == "Annually") {
        return "Standard Plan (Annual)";
      }

      return "Standard Plan";
    } else if (teamMembers > 3) {
      if (billingPeriod == "Quarterly") {
        return "Growth Plan (Quarterly)";
      }
      if (billingPeriod == "Annually") {
        return "Growth Plan (Annual)";
      }
      return "Growth Plan";
    }
  }
};

export const handleFetchUserDetails = async () => {
  try {
    const response = await getUserDetailsByTokenNewImplementation();
    const businessStore = useBusinessStore();
    const userStore = useUsersStore();
    userStore.currentUserBusinessLevel = response.data;
    // update list of business
    businessStore.getBusiness();
    if (businessStore.businesses?.length) {
      // checks the current active business and updates the exact active business on refresh in case there is a new change in the business object
      const activeBusinessId = businessStore.activeBusiness?.business.id;
      businessStore.activeBusiness = businessStore?.businesses.find(
        (business: any) => business.business.id == activeBusinessId
      )!;
    }
    userStore.user_role = businessStore.activeBusiness?.role as string;
  } catch (error) {
    logger.error(error, "Error while Fetching User details");
  }
};
export const updateFeedbackSheet = async () => {
  await updateFeedback();
};
export const getPermissions = async () => {
  const businessStore = useBusinessStore();
  const userId = businessStore.currentUserId as number;
  try {
    const res = await fetchTeamMemberDetails(userId);
    return res.data.permission;
  } catch (error: any) {
    notify({
      text: error?.message || error?.detail || "Unable to get permissions",
      type: "error",
    });
  }
};

export const handlePartnerSigin = async (callbackFunction: any) => {
  const authStore = useAuthStore();
  const router = useRouter();
  try {
    const queryString = window.location.href.split("?")[1];
    if (!queryString) return;

    const paramsArray = queryString.split("&");
    const queryParams = {} as any;

    for (const param of paramsArray) {
      const [key, value] = param.split("=");
      queryParams[key] = decodeURIComponent(value.replace(/\+/g, " "));
    }

    let payload: { mobile?: string; scheme?: string } = {
      scheme: "SMS",
    };
    if (queryParams.id) {
      payload = {
        mobile: validateMobile(queryParams.id),
      };
    }

    if (queryParams.trxref && authStore.mobile) {
      // update the payload with mobile number from signup page if redirected from paystack
      payload.mobile = authStore.mobile;
    }

    if (payload.mobile) {
      authStore.mobile = payload.mobile;
    }

    if (queryParams?.ref === "partner") {
      if (queryParams?.code === "WGH") {
        trackAmplitudeEvent(AMPLITUDE_EVENTS.WGH_REFERALL_SIGNUP, queryParams);
      }
    }

    if (queryParams?.ref === "go-global-signin" && authStore.mobile) {
      payload.mobile = authStore.mobile;
    }

    await callbackFunction(payload);
  } catch (error) {
    logger.error(error, "Error on signing in partners");
    router.push("/sign-in");
  }
};

export function removeDialCode(phoneNumber: string) {
  // Define an array of common international dial codes
  const dialCodes = ["+234", "+1", "+254", "234", "1", "254"];
  // Loop through the dial codes and check if the phone number starts with any of them
  for (const code of dialCodes) {
    if (phoneNumber.startsWith(code)) {
      // If a dial code is found, return the phone number with the dial code removed
      return phoneNumber.slice(code.length);
    }
  }
  // If no dial code is found, return the original phone number
  return phoneNumber;
}

export const validateAuthInput = (input: string) => {
  if (!input) return false;
  const text = input.trim();
  if (text.length < 1) {
    return false;
  }

  if (text.replace(/[^a-zA-Z]/g, "").length < 1) {
    return false;
  }

  return true;
};
export const sendVerifyEmail = async (email: string, resend = false) => {
  const authStore = useAuthStore();
  const isValidEmail = /.+@.+\..+/.test(email);
  if (!isValidEmail) {
    notify({
      type: "error",
      title: "Invalid Email",
      text: "Please enter a valid email address",
    });
    return;
  }
  try {
    authStore.loading = true;
    await sendVerificationEmail({ email: email });
    if (resend) {
      notify({
        type: "success",
        title: "Email Resent",
        text: "Verification email has been resent",
      });
    }
  } catch (error: any) {
    notify({
      type: "error",
      title: "Error",
      text:
        error?.message ||
        error?.detail ||
        " An error occured with sending verification email",
    });
  } finally {
    authStore.loading = false;
  }
};
export const sendResetVerifyEmail = async (email: string, resend = false) => {
  const authStore = useAuthStore();
  const isValidEmail = /.+@.+\..+/.test(email);
  if (!isValidEmail) {
    notify({
      type: "error",
      title: "Invalid Email",
      text: "Please enter a valid email address",
    });
    return;
  }
  try {
    authStore.loading = true;
    await requestResetPaswordEmail({ email: email });
    authStore.resetPasswordData.email = email;
    if (resend) {
      notify({
        type: "success",
        title: "Email Resent",
        text: "Verification email has been resent",
      });
    }
  } catch (error: any) {
    notify({
      type: "error",
      title: "Error",
      text: error?.message || error?.detail || "Something went wrong",
    });
  } finally {
    authStore.loading = false;
  }
};
export const handleConfirmPasswordReset = async (
  newPassword: string,
  id: string,
  token: string
) => {
  const authStore = useAuthStore();
  try {
    authStore.loading = true;
    const encrptKey = await getEncryptionKey();
    await requestPasswordResetConfirmation({
      uid: id,
      token: token,
      new_password: encryptToken(
        encrptKey.data.public_key,
        newPassword
      ) as string,
    });
    return true;
  } catch (error: any) {
    notify({
      type: "error",
      title: "Error",
      text: error?.message || error?.detail || "Something went wrong",
    });
  } finally {
    authStore.loading = false;
  }
};

export const useSigninWithEmail = () => {
  const router = useRouter();
  const siginInWithEmail = async (data: {
    email: string;
    password: string;
  }) => {
    const authStore = useAuthStore();
    const isValidEmail = /.+@.+\..+/.test(data.email);
    if (!isValidEmail) {
      notify({
        type: "error",
        title: "Invalid Email",
        text: "Please enter a valid email address",
      });
      return;
    }
    try {
      authStore.loading = true;
      const encrptKey = await getEncryptionKey();
      const res = await loginWithEmail({
        email: data.email,
        password: encryptToken(
          encrptKey.data.public_key,
          data.password
        ) as string,
      });
      if (res) {
        await authStore.login(res.data, true, data.email);
        return res;
      }
    } catch (error: any) {
      // if (
      //   error?.message &&
      //   error?.message?.includes("detail was found in our records")
      // ) {
      //   authStore.emailSignInErrMsg = error?.message as string;
      //   authStore.email = data.email;
      //   router.push("/verify-signin-token");
      // } else
      if (
        error.code == "4014" ||
        error?.message?.toLowerCase() ===
          "email has not been verified. please verify your email or reset your password"
      ) {
        setTimeout(async () => {
          authStore.email = data.email;
          authStore.emailSignInErrMsg = error?.message as string;
          await router.push("/verify-signin-token");
        }, 1000);
      } else if (error.code == "4001" || error.code == "4005") {
        setTimeout(() => {
          router.push("/sign-up");
        }, 1000);
      }

      notify({
        type: "error",
        title: "Error",
        text:
          error?.message || error?.detail || error || "Something went wrong",
      });
    } finally {
      authStore.loading = false;
    }
  };
  return {
    siginInWithEmail,
  };
};
