import requestClient from "../../api/requestClient";
import { BASE_API } from "../../constants";
import { getActiveBusinessId, ResponseData } from "../../queries";
import { CallQueueApiResponse } from "../../types/callQueue";

export const createCampaign = async (payload: object) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/`,
    { json: payload }
  );
};
export const deleteCampaign = async (campaignId: number) => {
  return await requestClient.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/`,
    { json: [campaignId] }
  );
};

export const getAllCampaigns = async (
  page: number,
  filters: { [key: string]: any } | null
): Promise<ResponseData> => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/?&page_index=${page}&page_size=${10}`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};

export const getCampaignSummary = async (
  filters: { [key: string]: any } | null
): Promise<ResponseData> => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaigns/summary/?`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};

export const exportCampiagnListingCsv = async (
  filters: { [key: string]: any } | null
) => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/export/?`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.download(
    `${link}`,
    { blobType: "text/csv" },
    "Campaign List logs"
  );
};

export const getCampaign = async (campaignId: number) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/`
  );
};

export const assignAgentToCampaign = async (
  campaignId: number,
  payload: { agent_ids: number[] }
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/assign-agents/`,
    { json: payload }
  );
};

export const assignContactsToAgents = async (
  campaignId: number,
  payload: { distributions: { agent_id: number; contact_count: number }[] }
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/assign-contacts/`,
    { json: payload }
  );
};

export const getAgentRankings = async (campaignId: number) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/agent-rankings/`
  );
};
export const getCampaignContacts = async (
  campaignId: number,
  page: number,
  filters: { [key: string]: any } | null
) => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/contacts/?&page_index=${page}&page_size=${10}`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};

export const getCampaignReport = async (
  campaignId: number,
  filters: { [key: string]: any } | null
) => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/summary/?`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};
export const getCustomerInterestLevelData = async (campaignId: number) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/customer-interest-levels/`
  );
};
export const getRevenueTargetData = async (
  campaignId: number,
  filters: { [key: string]: any } | null
) => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/revenue-target/?`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};
export const getConversionTargetData = async (
  campaignId: number,
  filters: { [key: string]: any } | null
) => {
  let link = `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/conversion-target/?`;
  if (filters) {
    for (const key in filters) {
      if (filters[key]) {
        link += `&${key}=${filters[key]}`;
      }
    }
  }
  return await requestClient.get(link);
};

export const addBulkContactToCampaign = async (
  campaignId: number,
  payload: {
    contacts: object[];
    single_match_action: string;
    multiple_match_resolution_strategy: string;
  }
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/contacts/bulk-create/`,
    { json: payload }
  );
};

export const changeCampaignStatus = async (
  campaignId: number,
  status: string
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/change-status/`,
    { json: { status } }
  );
};

export const deleteBulkContactsFromCampaign = async (
  campaignId: number,
  payload: { contact_ids: number[] }
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/remove-contacts/`,
    { json: payload }
  );
};

export const editCampaign = async (campaignId: number, payload: object) => {
  return await requestClient.put(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/`,
    { json: payload }
  );
};

export const getCampaignCallScripts = async (campaignId: number) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/call-scripts/`
  );
};
export const getAllAgentCampaigns = async (
  agentId: number,
  page: number,
  perPage: number = 10
) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaigns/agent/${agentId}/minimal/?&page_index=${page}&page_size=${perPage}`
  );
};
export const getSingleAgentCampaign = async (
  agentId: number,
  campaignId: number
) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/agent/${agentId}/`
  );
};
export const getAgentCallQueueCampaign = async (
  agent_id: any,
  link?: string
): Promise<ResponseData> => {
  if (link) return await requestClient.get(link);
  else
    return await requestClient.get(
      `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/agent/${agent_id}/call-queues/`
    );
};

export const getAgentCallQueueItemsByCampaign = async (
  agent_id: any,
  call_queue_id: any,
  campaignId: number
): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/agent/${agent_id}/call-queues/${call_queue_id}/items/`
  );
};

export const getAgentCallQueueDetailsInCampaign = async (
  agent_id: any,
  call_queue_id: any,
  campaignId: number
): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/agent/${agent_id}/call-queues/${call_queue_id}/`
  );
};
export const removeContactFromQueueCampaign = async (
  campaignId: number,
  call_queue_id?: string | number,
  item_id?: string | number
) => {
  return (await requestClient.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/call-queue-item/remove/?call_queue_id=${call_queue_id}&item_id=${item_id}`
  )) as CallQueueApiResponse;
};
export const getAgentReankingsAcrossCampaigns = async () => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/agent-rankings/`
  );
};
export const editCampaignCallScripts = async (
  campaignId: number,
  scriptId: number,
  payload: object
) => {
  return await requestClient.put(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/call-script/${scriptId}/`,
    { json: payload }
  );
};
export const replaceCampaignNumber = async (
  campaignId: number,
  payload: object
) => {
  return await requestClient.put(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/sales-campaign/${campaignId}/replace-business-number/`,
    { json: payload }
  );
};
