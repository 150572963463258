import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import { getActiveBusinessId } from "../queries";

interface ResponseData {
  [key: string]: any;
}

type TFetchReceiverBusiness = {
  businessId: string | number;
  userId: string | number;
};

export const fetchReceiversByBusiness = async ({
  businessId,
  userId,
}: TFetchReceiverBusiness): Promise<ResponseData> => {
  // get number under a business
  return await requestClient.get(
    `${BASE_API}/api/business/${businessId}/user/${userId}/`
  );
};
export const fetchBusinessNumbersUnderABusiness = async (
  businessId?: number
): Promise<ResponseData> => {
  // get number under a business
  return await requestClient.get(
    `${BASE_API}/api/business/${
      businessId ? businessId : getActiveBusinessId()
    }/number/`
  );
};
