interface PlatformDetails {
  osType: string;
  osVersion: string;
  source: string;
  deviceInfo: string;
}
export async function getIP() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    localStorage.setItem("ipAddress", data.ip);
  } catch (error) {}
}

export function getPlatformDetails(): PlatformDetails {
  const platformDetails: PlatformDetails = {
    osType: "Unknown",
    osVersion: "Unknown",
    source: "Unknown",
    deviceInfo: "Unknown",
  };

  const userAgent = navigator.userAgent;

  // get ostype
  if (/windows/i.test(userAgent)) {
    platformDetails.osType = "Windows";
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    platformDetails.osType = "macOS";
  } else if (/android/i.test(userAgent)) {
    platformDetails.osType = "Android";
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    platformDetails.osType = "iOS";
  } else if (/linux/i.test(userAgent)) {
    platformDetails.osType = "Linux";
  }

  // get browser source
  if (/chrome/i.test(userAgent) && !/edge|edg/i.test(userAgent)) {
    platformDetails.source = "Chrome";
  } else if (/safari/i.test(userAgent) && !/chrome/i.test(userAgent)) {
    platformDetails.source = "Safari";
  } else if (/firefox/i.test(userAgent)) {
    platformDetails.source = "Firefox";
  } else if (/edg/i.test(userAgent)) {
    platformDetails.source = "Edge";
  } else if (/opera|opr/i.test(userAgent)) {
    platformDetails.source = "Opera";
  } else platformDetails.source = "Unknown";

  // get OS version
  const osVersionMatch = userAgent.match(
    /(Windows NT|Mac OS X|Android|CPU OS|Linux)[\s_/]?([\d._]+)/
  );

  platformDetails.osVersion = osVersionMatch
    ? osVersionMatch[2].replace(/_/g, ".")
    : "Unknown";
  platformDetails.deviceInfo = navigator.platform || "Unknown";

  return platformDetails;
}
