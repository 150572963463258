// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  defaults: {
    VBtn: {
      style: "text-transform: none;",
    },
  },

  icons: {
    defaultSet: "mdi",
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#00AEEF",
          secondary: "#FF3B30",
          accent: "#101928",
          border: "#E4E7EC",
          "accent-blur": "#344054",
        },
      },
    },
  },
});
