import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import logger from "../logger";
import { downloadBillingHistory, getActiveBusinessId } from "../queries";
import {
  MakePayment,
  Plans,
  Subscription,
  Subscriptions,
} from "../types/billings";

const BASE_ENDPOINT = `${BASE_API}/api`;

export const getSubscriptionPlans = async () => {
  return (await requestClient.get(
    `${BASE_ENDPOINT}/payments/subscription-plan/?page_size=60`
  )) as Plans;
};

export const getCurrentUserSubscriptions = async (): Promise<Subscriptions> => {
  const subscriptions = await requestClient.get(
    `${BASE_ENDPOINT}/subscriptions/`
  );

  const data = subscriptions.data;

  return {
    count: data?.count,
    next: data?.next,
    results: data?.results,
    previous: data?.previous,
  };
};

export const getCurrentBusinessSubscriptions =
  async (): Promise<Subscriptions> => {
    const subscriptions = await requestClient.get(
      `${BASE_ENDPOINT}/subscriptions/?business_id=${getActiveBusinessId()}`
    );

    const data = subscriptions.data;

    return {
      count: data?.count,
      next: data?.next,
      results: data?.results,
      previous: data?.previous,
    };
  };

export const getSubscription = async (
  id: string | number
): Promise<Subscription> => {
  const subscription = await requestClient.get(
    `${BASE_ENDPOINT}/subscriptions/${id}/`
  );

  return subscription?.data!;
};

export const reActivateBusinessSubscrition = async (id: string) => {
  const response = await requestClient.post(
    `${BASE_ENDPOINT}/numbers/${id}/re_activate_subscription/`
  );

  return response;
};

export const getBillingPaymentReports = async (filters = {}) => {
  const query = new URLSearchParams(filters);
  const queryString = query.toString();
  const business_id = getActiveBusinessId();
  const reports = await requestClient.get(
    `${BASE_ENDPOINT}/payments/history/?business_id=${business_id}&${queryString}`
  );

  if (!reports?.data) return { data: [] };

  return reports?.data ?? [];
};

export const downloadBillingRecords = async (
  user_id: string,
  from_date: string,
  to_date: string
) => {
  try {
    await downloadBillingHistory(user_id, from_date, to_date);
  } catch (error) {
    logger.error(error, "Error downloading billing records");
  }
};

export const getExistingBusiness = async () => {
  const businesses = await requestClient.get(`${BASE_ENDPOINT}/business/`);
  return businesses?.data ?? [];
};

export const makePayment = async (data: MakePayment) => {
  const payment = await requestClient.patch(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/`,
    { json: data }
  );
  return payment;
};

export const cancel_subscription = async (business_number_id: number) => {
  const canceled = await requestClient.post(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/cancel/`,
    {
      json: {
        business_number_id,
        reason_for_cancellation: "",
      },
    }
  );
  return canceled;
};

export const getCards = async (userId: string | number) => {
  const cards = await requestClient.get(
    `${BASE_ENDPOINT}/users/${userId}/cards/`
  );

  return cards?.data ?? [];
};
export const getBusinessCards = async () => {
  const cards = await requestClient.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/card/`
  );

  return cards?.data ?? [];
};

export const addCard = async ({
  amount = 50,
  ...data
}: {
  business: string;
  payment_mode: string;
  amount?: number;
}) => {
  const cards = await requestClient.post(`${BASE_ENDPOINT}/v2/top_up/`, {
    json: {
      amount,
      ...data,
    },
  });

  return cards?.data ?? [];
};

export const deleteCard = async ({
  userId,
  card_num,
}: {
  userId: string | number;
  card_num: Array<string>;
}) => {
  const cards = await requestClient.post(
    `${BASE_ENDPOINT}/users/${userId}/delete_cards/`,
    { json: { card_num } }
  );

  return cards?.data ?? [];
};

export const getSubscriptionStatus = async () => {
  const subscriptionStatus = await requestClient.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/status/`
  );
  return subscriptionStatus?.data?.data?.status ?? "";
};

export const getUnpaidInvoices = async (business_id?: number | string) => {
  if (getActiveBusinessId() && !business_id) {
    business_id = getActiveBusinessId();
  }
  const invoices = await requestClient.get(
    `${BASE_ENDPOINT}/business/${business_id}/invoice/`,
    { level: "public" }
  );
  return invoices?.data?.data ?? [];
};

export const clearPendingInvoices = async (business_id?: number | string) => {
  if (getActiveBusinessId()) {
    business_id = getActiveBusinessId();
  }
  const paymentUrl = await requestClient.post(
    `${BASE_ENDPOINT}/business/${business_id}/invoice/clear-pending/`,
    { level: "public" }
  );
  return paymentUrl?.data?.data;
};

export const getSubscriptionDetails = async () => {
  const subscriptiondetails = await requestClient.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/subscription/`
  );
  return subscriptiondetails;
};
export const authorizeCard = async () => {
  return await requestClient.post(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/authorize-card/`,
    {}
  );
};

export const getCardFailureReason = async (card_id: string) => {
  return await requestClient.get(
    `${BASE_ENDPOINT}/business/${getActiveBusinessId()}/card/${card_id}/failure-reason/`
  );
};
