import {
  SubscriptionPlan,
  provisionNumberCharacter,
} from "@/helpers/types/numberProvision";
import { defineStore } from "pinia";

interface ProvisionState {
  selectedNumber: string[];
  countryCode: string;
  tabs: provisionNumberCharacter[];
  numberProvisioningProgress: number;
  additionalProvisioningSteps: Step[];
  currentStep: number;
  selectedQuantity: number;
  selectedPlan: SubscriptionPlan | null;
}

interface Step {
  name: string;
  step: number;
  desc: string;
}

export const useNumberProvisionStore = defineStore({
  id: "number-provisioning",
  state: (): ProvisionState => ({
    selectedNumber: [],
    countryCode: "NG",
    numberProvisioningProgress: 0, // set the progress bar in activating page
    tabs: [
      {
        title: "Landline",
        content:
          "A landline number is a telephone number with 0201 prefix for Multi-purpose business-related communications.",
        response: [],
      },
      {
        title: "Mobile number",
        content:
          "A mobile number is a unique telephone number for Multi-purpose business-related communications.",
        response: [],
      },
      {
        title: "Special number",
        content:
          "A Special number is a unique telephone number that serves a specific purpose or special feature. E.g Telemarketing, Voice OTP, Outbound customer support calls.",
        response: [],
      },
      // {
      //   title: "Toll-free",
      //   content:
      //     "A toll-free line is a telephone number that allows callers to reach a business or organization without incurring any charges for the call. Instead, the recipient of the call (the business or organization) bears the cost of the call . E.g Emergency Hotlines, customer service, support hotlines, and sales inquiries.",
      //   response: [],
      // },
    ],
    additionalProvisioningSteps: [
      {
        name: "Choose a Plan",
        step: 1,
        desc: "Select from a list of available phone number plans.",
      },
      {
        name: "Select quantity",
        step: 2,
        desc: "Select from a list of available phone number plans.",
      },
      {
        name: "Make payment",
        step: 3,
        desc: "Select from a list of available phone number plans.",
      },
      {
        name: "Choose number",
        step: 4,
        desc: "Select from a list of available phone number plans.",
      },
    ],
    currentStep: 1,
    selectedQuantity: 0,
    selectedPlan: null,
  }),
  getters: {},
  actions: {},
  persist: {
    storage: localStorage,
    paths: ["selectedNumber"],
  },
});
