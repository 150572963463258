<template>
  <button
    class="global-btn px-4 font-weight-medium"
    :class="textColor"
    v-bind="attrs"
    color="textColor"
    :loading="isLoading"
    :disabled="isLoading"
    :style="{ boxShadow, backgroundColor: buttonColor, color: textColor }"
    @click.prevent="$emit('click')"
  >
    <span v-if="!isLoading">{{ title }}</span>

    <template v-else>
      <v-progress-circular
        indeterminate
        size="20"
        color="white"
      ></v-progress-circular>
    </template>
  </button>
  <!-- hkh -->
  <!-- hkh -->
</template>

<script lang="ts">
  import { defineComponent, useAttrs } from "vue";

  export default defineComponent({
    props: {
      boxShadow: {
        type: String,
      },
      buttonColor: {
        type: String,
      },
      textColor: {
        type: String,
      },
      title: {
        type: String,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["click"],
    setup(props) {
      const boxShadow = props.boxShadow;
      const buttonColor = props.buttonColor;
      const textColor = props.textColor;
      const title = props.title;

      const attrs = useAttrs();

      return {
        boxShadow,
        buttonColor,
        textColor,
        title,
        attrs,
      };
    },
    data() {
      return {};
    },
  });
</script>

<style>
  .global-btn {
    border-radius: 8px !important;
    height: 42px;
    box-shadow: 0px 1px 1px 0px #0000001a;
  }
</style>
