import { PINIA_PERSIST_OPTIONS } from "@/helpers/constants";
import { defineStore } from "pinia";

interface NumberAddressAddOnState {
  countryCode: string;
  country: string;
  numberRegion: string;
  selectedPeriod: string;
  addresses: any[];
  stateCode: string;
  pageSize: number;
  internationalPlans: any;
  selectedNumber: any;
  selectedAddress: any;
  allPlans: any;
  allPlansCountries: any;
  selectedPlan: any;
  formDetails: {
    dialCode: string;
    [key: string]: any;
  };
  selectedCountry: any;
}

const defaultState = () => ({
  countryCode: "US",
  country: "United States",
  numberRegion: "US",
  selectedPeriod: "monthly",
  addresses: [],
  stateCode: "",
  pageSize: 100,
  internationalPlans: null,
  selectedNumber: null,
  selectedAddress: null,
  selectedPlan: null,
  allPlansCountries: [],
  allPlans: null,
  formDetails: { dialCode: "+234" },
  selectedCountry: {
    flag: "fi-us",
    country: "United States of America",
    countryCode: "US",
  },
});

export const useNumberAddressAddOnStore = defineStore({
  id: "number-address-addon",
  state: (): NumberAddressAddOnState => defaultState(),
  actions: {
    resetStore() {
      // Replace the store state with the default state
      Object.assign(this, defaultState());
    },
  },
  persist: PINIA_PERSIST_OPTIONS,
});
