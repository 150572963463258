import { findRecommendedPlan, validateAuthInput } from "@/helpers/auth";
import { PAYSTACK_KEY, PINIA_PERSIST_OPTIONS } from "@/helpers/constants";
import {
  recommendationDataEventMap,
  trackAmplitudeEvent,
} from "@/helpers/integrations/analytics/amplitude";
import { AMPLITUDE_EVENTS } from "@/helpers/integrations/analytics/events";
import logger from "@/helpers/logger";
import {
  campaignPurchase,
  getPlans,
  getWebPaymentPurchaseLinkSignUpVersionTwo,
  sendRecommendation,
  sendSignupPurchaseInvoice,
  updateRecommendation,
} from "@/helpers/queries";
import { fetchNumbers } from "@/helpers/queries/add-ons/address-numbers";
import { getPendingNumberPayment } from "@/helpers/queries/onboarding";
import { recommendationData, WebPurchaseData } from "@/helpers/types/global";
import router from "@/router";
import { notify } from "@kyvg/vue3-notification";
import Paystack from "@paystack/inline-js";
import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import { useUsersStore } from "./users.store";
import { useUtilsStore } from "./utils.store";

interface signup {
  businessName: string;
  country: string;
  role: string;
  industry: string;
  hearAboutUs: string;
  otherSource: string;
  promo_code: string | null;
  recommended_plan?: any;
  tabs: { title: string }[];
  numberOfReceivers: number;
  getInternationalNumber: boolean;
  activeTab: number;
  plans: any[];
  selectedTimeDuration: any;
  otherRole: string;
  selectedNumber: {
    country_code: string;
    phone_number: string;
    provider: string;
    state?: null | string;
  } | null;
  hasCoupon: boolean;
  isLoading: boolean;
  isFetchingAmount: boolean;
  invoiceDetails: {
    fullName: string;
    email: string;
    phoneNumber: string;
  } | null;
  skipPaystack: boolean;
  showInvoiceSentModal: boolean;
  requestedSpecialNumber: boolean;
  hasClickedPurchase: boolean;
  showInvoiceModal: boolean;
  showPricingDrawer: boolean;
  claimedNumber: boolean;
  completedStep: number;
  is_available_number_changed: boolean;
  preSaleId: string | null;
  available_number: string | null;
  isFetchingUserInfo: boolean;
  campaignHashId: string;
  showSuccessSpecialNumberPurchaseModal: boolean;
  globalId: string;
}
export const useSignUpStore = defineStore({
  id: "signup",
  state: (): signup => ({
    businessName: "",
    country: "Nigeria",
    role: "",
    industry: "",
    hearAboutUs: "",
    otherSource: "",
    promo_code: null,
    recommended_plan: {} as {},
    numberOfReceivers: 0,
    otherRole: "",

    getInternationalNumber: false,
    plans: [],
    tabs: [
      {
        title: "Monthly",
      },
      {
        title: "Quarterly",
      },
      {
        title: "Annually",
      },
    ],
    activeTab: 0,
    selectedTimeDuration: 0,
    selectedNumber: null,
    hasCoupon: false,
    isLoading: false,
    isFetchingAmount: false,
    invoiceDetails: null,
    skipPaystack: false,
    showInvoiceSentModal: false,
    requestedSpecialNumber: false,
    hasClickedPurchase: false,
    showInvoiceModal: false,
    showPricingDrawer: false,
    claimedNumber: false,
    completedStep: 0,
    is_available_number_changed: false,
    preSaleId: null,
    available_number: null,
    isFetchingUserInfo: false,
    campaignHashId: "",
    showSuccessSpecialNumberPurchaseModal: false,
    globalId: "",
  }),
  getters: {
    filterDurationTabs(): { title: string }[] {
      const utilsStore = useUtilsStore();
      if (utilsStore.signInSource === "customer-winback")
        return [{ title: "Monthly" }];
      else return this.tabs;
    },
    calculatePrice(): any {
      let originalPrice = 0;
      const discountedPrice = this.recommended_plan?.standard_cost;
      let monthlyPrice = this.recommended_plan?.standard_cost;

      if (this.activeTab === 1) {
        // quaterly
        originalPrice = (100 / 85) * this.recommended_plan?.standard_cost;
        monthlyPrice = this.recommended_plan?.standard_cost / 3;
      }

      if (this.activeTab === 2) {
        // yearly
        originalPrice = (100 / 80) * this.recommended_plan?.standard_cost;
        monthlyPrice = this.recommended_plan?.standard_cost / 12;
      }
      return {
        originalPrice,
        discountedPrice,
        monthlyPrice,
      };
    },
    canSubmitRecommendationForm(): boolean {
      const referral_source =
        this.hearAboutUs?.toLowerCase() === "others"
          ? this.otherSource?.trim() || null
          : this.hearAboutUs?.trim() || null;

      const job_title =
        this.role?.toLowerCase() === "others"
          ? this.otherRole?.trim() || ""
          : this.role?.trim() || "";

      return (
        validateAuthInput(this.businessName) &&
        validateAuthInput(this.role) &&
        !!this.industry &&
        !!this.numberOfReceivers &&
        referral_source !== null &&
        job_title !== null
      );
    },
    getRecommendationData(): recommendationData {
      const userStore = useUsersStore();

      const reccommendationData: recommendationData = {
        user_id: userStore?.currentUserBusinessLevel
          ? userStore?.currentUserBusinessLevel?.id
          : userStore.registerUser.id,
        businessName: this.businessName,
        first_name: userStore?.currentUserBusinessLevel
          ? userStore?.currentUserBusinessLevel?.first_name
          : userStore.registerUser.first_name,
        last_name: userStore?.currentUserBusinessLevel
          ? userStore?.currentUserBusinessLevel?.last_name
          : userStore.registerUser.last_name,
        email: userStore?.currentUserBusinessLevel
          ? userStore?.currentUserBusinessLevel?.personal_email
          : userStore.registerUser.personal_email,
        mobile: userStore?.currentUserBusinessLevel
          ? userStore?.currentUserBusinessLevel?.mobile
          : userStore.registerUser.mobile,
        team_size: this.numberOfReceivers,
        sector: this.industry,
        plan_id: this.recommended_plan?.id,
        business_name: this.businessName,
        job_title:
          this.role?.toLowerCase() === "others"
            ? this.otherRole?.trim() || null
            : this.role?.trim() || null,
        address: this.country,
        requires_usa_number: this.getInternationalNumber,
        makes_international_calls: this.getInternationalNumber,
        referral_source:
          this.hearAboutUs?.toLowerCase() === "others"
            ? this.otherSource?.trim() || null
            : this.hearAboutUs || null,
      };
      return reccommendationData;
    },
    canMoveToNextStep(): boolean | undefined {
      switch (this.completedStep) {
        case 0:
          return !!this.canSubmitRecommendationForm;
        case 1:
          return !!this.selectedNumber?.phone_number;
        case 2:
          return true;
      }
    },
  },
  actions: {
    findPlan() {
      const userStore = useUsersStore();
      const recommendedPlan = findRecommendedPlan(
        this.numberOfReceivers,
        this.getInternationalNumber,
        this.tabs[this.activeTab].title
      );

      if (this.plans.length > 0) {
        this.recommended_plan = this.plans.find((plan) =>
          recommendedPlan?.toLowerCase().includes(plan.title?.toLowerCase())
        );
        userStore.recommended_plan = this.recommended_plan;
      }
    },
    async activateTab(index: number) {
      const userStore = useUsersStore();
      this.isFetchingAmount = true;
      this.activeTab = index;
      userStore.activeTab = index;
      const term =
        this.tabs[this.activeTab].title?.toLowerCase() === "annually"
          ? "yearly"
          : this.tabs[this.activeTab].title?.toLowerCase();

      const response = await getPlans(`&plan_type=number&term=${term}`);
      this.plans = response.data;
      this.findPlan();
      this.isFetchingAmount = false;
    },
    syncWithValueWithUserStore() {
      const userStore = useUsersStore();

      this.businessName = userStore.registerBusiness.business_name ?? "";
      this.country = userStore.registerBusiness.address ?? "Nigeria";
      this.role = userStore.registerBusiness.job_title ?? "";
      this.industry = userStore.registerBusiness.sector ?? "";
      this.hearAboutUs = userStore.registerBusiness.referral_source ?? "";
      this.otherSource = userStore.registerBusiness.otherSource ?? "";
      this.otherRole = userStore.registerBusiness.otherRole ?? "";
    },
    async handleWinbackSignIn(planId?: string) {
      const userStore = useUsersStore();
      const utilsStore = useUtilsStore();
      const authStore = useAuthStore();
      this.isLoading = true;
      try {
        trackAmplitudeEvent(AMPLITUDE_EVENTS.WINBACK_CAMPAIGN_SIGNIN);

        await sendRecommendation(this.getRecommendationData);
        await campaignPurchase(planId ? { plan_id: planId } : undefined);

        setTimeout(async () => {
          userStore.user_role = "owner";
          const pendingSetupResponse = await getPendingNumberPayment();
          const pendingNumberSetup = pendingSetupResponse.data;
          utilsStore.setSetUpPayload(pendingNumberSetup?.data);
          authStore.loading = false;

          notify({
            text: "Successfully Purchased a Business number. Proceed to provision a number.",
            type: "success",
          });

          router.push("/managers/provision-number");
        }, 5000);
        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;

        notify({
          text: `${error?.message || error}`,
          type: "error",
        });
        if (
          String(error).toLowerCase() ===
          "you are not eligible for the campaign. please proceed to purchase an additional number."
        ) {
          utilsStore.signInSource = "";
        }
      }
    },
    async choosePaymentOption() {
      try {
        this.isLoading = true;

        this.hasClickedPurchase = true;

        this.isLoading = true;

        const userStore = useUsersStore();
        const authStore = useAuthStore();
        const utilsStore = useUtilsStore();
        const webPurchaseData: WebPurchaseData = {
          first_name: userStore?.currentUserBusinessLevel
            ? userStore?.currentUserBusinessLevel?.first_name
            : userStore.registerUser.first_name,
          last_name: userStore?.currentUserBusinessLevel
            ? userStore?.currentUserBusinessLevel?.last_name
            : userStore.registerUser.last_name,
          email: userStore?.currentUserBusinessLevel
            ? userStore?.currentUserBusinessLevel?.personal_email
            : userStore.registerUser.personal_email,
          phone_number: userStore?.currentUserBusinessLevel
            ? userStore?.currentUserBusinessLevel?.mobile
            : userStore.registerUser.mobile,
          plan_id: this.recommended_plan?.id,
          numbers: [
            {
              phone_number: this.selectedNumber?.phone_number!,
              provider: this.selectedNumber?.provider!,
              country: this.selectedNumber?.country_code!,
              label: null,
            },
          ],
          business: {
            name: this.businessName,
            is_go_global: false,
          },
        };

        if (utilsStore.affiliateLinkRef) {
          // update payload before making request
          webPurchaseData.promotion_code = utilsStore.affiliateLinkRef;
        }
        if (authStore.couponCode) {
          webPurchaseData.coupon = authStore.couponCode;
        }
        if (this.hasCoupon) {
          webPurchaseData.coupon = this.promo_code as string;
        }
        this.hasClickedPurchase = true;
        let response;
        if (this.skipPaystack) {
          const payload = {
            data: webPurchaseData,
            bill_to: this.invoiceDetails?.fullName,
            bill_to_email: this.invoiceDetails?.email,
            bill_to_phone_number: this.invoiceDetails?.phoneNumber,
          };
          await sendSignupPurchaseInvoice(payload);

          trackAmplitudeEvent(AMPLITUDE_EVENTS.INVOICE_PAYMENT_LINK_SENT, {
            selected_number: this.selectedNumber?.phone_number,
            selected_country_code: this.selectedNumber?.country_code,
            plan: this.recommended_plan?.title,
            amount: this.calculatePrice.originalPrice.toString(),
            device: "web",
          });

          this.showInvoiceSentModal = true;
          this.isLoading = false;
        } else {
          trackAmplitudeEvent(AMPLITUDE_EVENTS.PAYMENT_ATTEMPTED, {
            selected_number: this.selectedNumber?.phone_number,
            selected_country_code: this.selectedNumber?.country_code,
            plan: this.recommended_plan?.title,
            amount: this.calculatePrice.originalPrice.toString(),
            device: "web",
          });
          response =
            await getWebPaymentPurchaseLinkSignUpVersionTwo(webPurchaseData);
          this.isLoading = false;
          this.payWithPaystack(
            webPurchaseData.email,
            this.calculatePrice.discountedPrice?.toString(),
            response.ref
          );
        }
      } catch (error: any) {
        this.isLoading = false;
        notify({
          text: `${error?.message || error}`,
          type: "error",
        });
      }
    },
    payWithPaystack(email: string, amount: number, reference: string) {
      const popup = new Paystack();
      popup.checkout({
        key: PAYSTACK_KEY,
        email,
        amount: amount * 100,
        reference,
        onError: (error: any) => {
          notify({
            text: `${error?.message}`,
            type: "error",
          });
        },
        onSuccess: () => {
          if (this.requestedSpecialNumber) {
            this.showSuccessSpecialNumberPurchaseModal = true;
          } else {
            router.replace("/email-verification");
            this.clearStore(); // clear business information after successful payment
          }
        },
      });
    },
    clearStore() {
      this.businessName = "";
      this.getInternationalNumber = false;
      this.role = "";
      this.hearAboutUs = "";
      this.numberOfReceivers = 0;
      this.country = "";
      this.industry = "";
      this.claimedNumber = false;
      this.completedStep = 0;
      this.selectedNumber = null;
      this.requestedSpecialNumber = false;
      this.showSuccessSpecialNumberPurchaseModal = false;
      this.globalId = "";
    },
    async fetchSpecialNumber() {
      const response = await fetchNumbers(
        `?country_code=NG&page_size=4&provider=CDV`
      );
      this.selectedNumber = response.data.data[0];
    },
    async onSubmitBusinessInfo(isEdit?: boolean, hashId?: string) {
      const userStore = useUsersStore();

      this.isLoading = true;

      try {
        userStore.registerBusiness = this.getRecommendationData;

        if (!isEdit) {
          trackAmplitudeEvent(AMPLITUDE_EVENTS.HEARD_ABOUT_US, {
            Source:
              this.hearAboutUs?.toLowerCase() === "others"
                ? this.otherSource
                : this.hearAboutUs,
          });

          const recommendationEvent =
            recommendationDataEventMap[
              this.recommended_plan?.title?.toLowerCase()
            ]; // gets event from current source
          if (recommendationEvent) {
            trackAmplitudeEvent(recommendationEvent);
          }
          const response = await sendRecommendation(this.getRecommendationData);
          this.preSaleId = response.data.id;
        }
        // edit preSale information to accept selected number
        else {
          if (hashId) {
            // update recommendation only for sign ups with hashid
            await updateRecommendation(
              this.preSaleId!,
              {
                ...this.getRecommendationData,
                available_number: this.selectedNumber?.phone_number,
              },
              hashId
            );
          }
          this.completedStep = 2;
        }
        this.isLoading = false;
      } catch (e: any) {
        notify({
          text: `${e.message || e}`,
          type: "error",
        });
        logger.error(e, "Error Signin up");
        this.isLoading = false;
      }
    },
  },

  persist: PINIA_PERSIST_OPTIONS,
});
