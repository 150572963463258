import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import { App, Plugin } from "vue";

export const customerIoPlugin: Plugin = {
  install(app: App) {
    app.config.globalProperties.$cioanalytics = AnalyticsBrowser.load(
      {
        cdnURL: "https://cdp-eu.customer.io",
        writeKey: import.meta.env.VITE_CUSTOMER_IO_WRITE_KEY,
      },
      {
        integrations: {
          "Customer.io In-App Plugin": {
            siteId: import.meta.env.VITE_CUSTOMER_IO_SITE_ID,
            // @ts-expect-error - This is a workaround to avoid type errors
            events: function (event: { type: string }) {
              switch (event.type) {
                case "in-app:message-opened":
                  // do something when a message is opened
                  break;
                case "in-app:message-dismissed":
                  // do something when a message is dismissed
                  break;
                case "in-app:message-action":
                  // do something when a message is interacted with
                  break;
                case "in-app:message-error":
                  // do something when a message errors
                  break;
              }
            },
          },
        },
      }
    );
  },
};
