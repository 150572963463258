import { Contact, ContactApiResponse } from "@/helpers/types/contact";
import { defineStore } from "pinia";
interface ContactState {
  savedContacts: ContactApiResponse | null;
  allContacts: Contact[];
  callQueueId: string;
  computedContactsList: Contact[];
  loadingContacts: boolean;
  selectedContact: Contact | null;
  selectedContacts: Contact[];
  totalContacts: number;
  selectedContactAndColor?: {
    gradColors: {
      color1: string;
      color2: string;
    };
    contact: Contact;
  };
  showContactDetailsModal: boolean;
}

export const useContactStore = defineStore({
  id: "contact",
  state: (): ContactState => ({
    savedContacts: null,
    allContacts: [],
    callQueueId: "",
    computedContactsList: [],
    loadingContacts: false,
    selectedContact: null,
    selectedContacts: [],
    totalContacts: 0,
    showContactDetailsModal: false,
  }),
  getters: {
    selectedContactIDs: (state: ContactState) => {
      const selectedIDs = [];
      for (let i = 0; i < state.selectedContacts.length; i++) {
        selectedIDs.push(state.computedContactsList[i].id); // Assuming each selected contact has an "id" property
      }
      return selectedIDs;
    },
  },
});
