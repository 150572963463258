import requestClient from "../../api/requestClient";
import { BASE_API } from "../../constants";
import { getActiveBusinessId } from "../customerengagement";

export const fetchNumbers = async (queries: string = "") => {
  return await requestClient.get(
    `${BASE_API}/api/available_number/numbers/` + `${queries}`,
    { level: "public" }
  );
};

export const fetchGlobalPlans = async (queries: string = "") => {
  return await requestClient.get(
    `${BASE_API}/api/payments/subscription-plan/` + `${queries}`,
    { level: "public" }
  );
};

export const purchaseInternationalNumber = async (payload: any) => {
  return await requestClient.post(
    `${BASE_API}/api/payments/number-purchase/web/international/`,
    { json: payload, level: "public" }
  );
};

export const purchaseAuthInternationalNumber = async (payload: any) => {
  return await requestClient.post(
    `${BASE_API}/api/payments/number-purchase/international/`,
    { json: payload }
  );
};

export const fetchGlobalAddress = async (queries: string = "") => {
  return await requestClient.get(
    `${BASE_API}/api/available-address/` + `${queries}`,
    { level: "public" }
  );
};

export const purchaseAddress = async (payload: any) => {
  if (getActiveBusinessId()) {
    return await requestClient.post(
      `${BASE_API}/api/payments/addon-purchase/`,
      { json: payload }
    );
  } else {
    return await requestClient.post(
      `${BASE_API}/api/payments/addon-purchase/web/`,
      { json: payload, level: "public" }
    );
  }
};

export const purchaseNumberAndAddress = async (payload: any) => {
  return await requestClient.post(
    `${BASE_API}/api/payments/number-addon-purchase/web/`,
    { json: payload, level: "public" }
  );
};

export const fetchPurchasedAddress = async (queries: string = "") => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/address/` +
      `${queries}`
  );
};

export const cancelAddressRenewal = async (id: string = "") => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/address/${id}/`
  );
};

export const getAddonPlans = async (queries: string = "") => {
  return await requestClient.get(
    `${BASE_API}/api/payments/addon-plan/` + `${queries}`,
    { level: "public" }
  );
};
