import logger from "@/helpers/logger";
import { useAuthStore } from "@/stores/auth.store";
import { useBusinessStore } from "@/stores/businesses.store";
import { useUsersStore } from "@/stores/users.store";
import snakeCase from "lodash/snakeCase";
import { getPlatformDetails } from "../../platform";
import { userActivityEvents } from "../../queries";

export interface UserActivityEvents {
  identifier_type: string; // Unique identifier for the event (required)
  identifier_value: string; // Identifies the object related to the event (required)
  event_time: string; // Timestamp of the event (required, ISO 8601 format)
  event_type?: string; // Type of event (default: "user_activity", optional)
  event_name: string; // Descriptive name of the event (required)
  properties?: Record<string, string | boolean>; // Custom properties for the event (optional, can be an empty object)
  device_type?: string; // Type of device triggering the event (optional)
  device_version?: string; // Version of the device (optional)
  user_agent?: string; // User agent string of the device (optional)
  source: string; // Source of the event (required)
  tags?: string[]; // Tags associated with the event (optional, can be an empty array)
  ip_address?: string | null;
}
export const sendUserActivityEvent = async (
  eventName: string,
  additionalProperties: Record<string, string | boolean | Date> = {}
) => {
  const { currentUserBusinessLevel, registerUser } = useUsersStore();
  const { osType, osVersion } = getPlatformDetails();
  const ipAddress = localStorage.getItem("ipAddress");
  const businessStore = useBusinessStore();
  const { isAuthenticated } = useAuthStore();
  const currentDate = new Date();

  const convertKeysAndValuesToSnakeCase = (
    obj: Record<string, any>
  ): Record<string, any> => {
    return Object.entries(obj).reduce(
      (acc, [key, value]) => {
        const snakeCaseKey = snakeCase(key); // Convert key to snake_case
        const snakeCaseValue =
          typeof value === "string" ? snakeCase(value) : value; // Convert value to snake_case if it's a string
        acc[snakeCaseKey] = snakeCaseValue;
        return acc;
      },
      {} as Record<string, any>
    );
  };

  const properties: Record<string, string | boolean> = {
    ...convertKeysAndValuesToSnakeCase(additionalProperties),
  };

  //  if authenticated is true
  if (isAuthenticated) {
    if (businessStore.activeProfile?.business.id) {
      properties.business_id = businessStore.activeProfile?.business.id;
      properties.user_role = businessStore.activeProfile?.role;
    }
    if (currentUserBusinessLevel?.id) {
      properties.user_id = currentUserBusinessLevel?.id;
    }
  }
  const emailIdentifier =
    currentUserBusinessLevel?.personal_email || registerUser?.personal_email;
  const event: UserActivityEvents = {
    identifier_type: emailIdentifier ? "email" : "ip_address",
    identifier_value: emailIdentifier || ipAddress,
    event_time: currentDate.toISOString(),
    event_type: "user_activity",
    event_name: eventName,
    device_version: osVersion,
    device_type: osType?.toLowerCase(),
    user_agent: navigator.userAgent,
    source: "web",
    ip_address: ipAddress,
    tags: [],
    properties,
  };
  try {
    await userActivityEvents(event);
  } catch (error) {
    logger.error(error, `Could not send user data`);
  }
};
