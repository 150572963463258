export function loadZohoScript(): void {
  if (document.getElementById("zsiqscript")) {
    return; // Prevent duplicate loading
  }

  window.$zoho = window.$zoho || {
    salesiq: {
      widgetcode:
        "siq2529e1c4be527ed3288dd117f4b59eaf10aa414d6cd6ca3e42542a7ab1b52528", // Replace with your actual widget code
      values: {},
      ready: () => {},
    },
  };

  const script = document.createElement("script");
  script.id = "zsiqscript";
  script.type = "text/javascript";
  script.defer = true;
  script.src = "https://salesiq.zohopublic.com/widget";

  document.body.appendChild(script);
}

export function removeZohoScript(): void {
  const script = document.getElementById("zsiqscript");

  if (script) {
    script.remove();
  }
}
