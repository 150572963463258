import JSEncrypt from "jsencrypt";

const exportPemKey = async (key: CryptoKey): Promise<string> => {
  const exported = await window.crypto.subtle.exportKey("spki", key);
  const exportedAsBase64 = btoa(
    String.fromCharCode.apply(null, Array.from(new Uint8Array(exported)))
  );
  return `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;
};

export const generateKeyPair = async (): Promise<{
  publicKey: string;
  privateKey: CryptoKey;
}> => {
  const keys = await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048, // can be 1024, 2048, or 4096
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: { name: "SHA-256" }, // can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
    },
    true, // whether the key is extractable (i.e., can be used in exportKey)
    ["encrypt", "decrypt"] // can be any combination of "sign" and "verify"
  );

  const publicKey = await exportPemKey(keys.publicKey);
  const privateKey = keys.privateKey;

  return { publicKey, privateKey };
};

function b64ToArrayBuffer(base64string: string): Uint8Array {
  return new Uint8Array(
    atob(base64string)
      .split("")
      .map((char) => char.charCodeAt(0))
  );
}

export const decryptData = async (
  privateKey: CryptoKey,
  encrypted: string
): Promise<string> => {
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "RSA-OAEP",
    },
    privateKey,
    b64ToArrayBuffer(encrypted)
  );
  return new TextDecoder().decode(decrypted);
};

// encrypt the token from login using library
export const encryptToken = (key: string, token: string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(key);
  const encrypted = encrypt.encrypt(token);
  return encrypted;
};
