import { BASE_API } from "@/helpers/constants";
import { useBusinessStore } from "@/stores/businesses.store";
import { useTeamStore } from "@/stores/team-member.store";
import requestClient from "../api/requestClient";
import { getActiveBusinessId, ResponseData } from "../queries";
import type {
  AddContactToQueuePayload,
  CallQueueApiResponse,
  ContactDistribution,
  updateQueuePointerPayload,
} from "../types/callQueue";

export const getCallQueues = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/?page_size=100`
  );
};

export const getAgentCallQueue = async (
  agent_id: any,
  link?: string
): Promise<ResponseData> => {
  if (link) return await requestClient.get(link);
  else
    return await requestClient.get(
      `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/agent/${agent_id}/`
    );
};

export const getAgentCallQueueItems = async (
  agent_id: any,
  call_queue_id: any
): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/agent/${agent_id}/items/?page_size=100`
  );
};

export const createCallQueue = async (payload: any): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/`,
    { json: payload }
  );
};

export const addContactToQueue = async (
  payload: AddContactToQueuePayload,
  call_queue_id?: string | number
) => {
  return (await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/items/`,
    { json: payload }
  )) as CallQueueApiResponse;
};

export const removeContactFromQueue = async (
  payload: AddContactToQueuePayload,
  call_queue_id?: string | number,
  agent_id?: string | number
) => {
  return (await requestClient.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/agent/${agent_id}/items/`,
    { json: payload }
  )) as CallQueueApiResponse;
};

export const updateQueuePointer = async (
  payload: updateQueuePointerPayload
) => {
  return (await requestClient.post(
    `${BASE_API}/api/agent/call-queue/update_queue_pointer/`,
    { json: payload }
  )) as CallQueueApiResponse;
};

export const fetchCallQueue = async (call_queue_id?: string, url?: string) => {
  const teamStore = useTeamStore();

  if (url) {
    return await requestClient.get(url);
  }

  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/agent/${
      teamStore.selectedTeamMember
    }/items/`
  );
};

export const reorderCallQueue = async (payload: any, call_queue_id: string) => {
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/reorder/`,
    { json: payload }
  );
};

export const reorderCallQueueAgent = async (
  payload: any,
  call_queue_id: string
) => {
  const businessStore = useBusinessStore();
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/${call_queue_id}/agent/${
      businessStore.currentUserId
    }/reorder/`,
    { json: payload }
  );
};

export const distributeContacts = async (payload: ContactDistribution) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/call-queue/distribute-contacts/`,
    { json: payload }
  );
};

export const distributeBulkUploadContacts = async (
  payload: ContactDistribution
) => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/contacts/bulk-create/distribute/`,
    { json: payload }
  );
};
