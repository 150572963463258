<template>
  <v-app>
    <v-main>
      <!-- for instances when a cancel event is created when a user pick it will trigger onCallEnded event which closes the dialer. -->
      <!--  Use the existence of peer to display -->
      <notifications class="notification" />

      <router-view />

      <Survey
        v-if="
          businessStore.businesses?.length &&
          !businessStore.activeProfile?.has_submitted_weekly_survey &&
          authStore.isAuthenticated
        "
      />
      <!-- TODO Add survey check -->
      <SurveyToggleCard
        v-if="
          authStore.isAuthenticated &&
          businessStore.businesses?.length &&
          utilsStore.showSurveyCard &&
          !smAndDown
        "
        class="feedback-card"
        @click="utilsStore.showSurveyModal = true"
      />

      <v-dialog
        v-model="utilsStore.showPostOnboarding"
        class="d-flex justify-center align-center"
        width="500"
        persistent
      >
        <OnboardingUpdate />
      </v-dialog>

      <v-dialog
        v-model="utilsStore.showVerifyModalForCall"
        class="d-flex justify-center align-center"
        width="400"
        persistent
      >
        <ShowVerifyModalForCall />
      </v-dialog>

      <v-dialog
        v-model="utilsStore.showLogoutModal"
        width="430"
        persistent
        transition="dialog-bottom-transition"
      >
        <Logout @toggle-logout="onCloseLogoutModal" />
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
  import { onMounted, watch } from "vue";
  import { useRoute, type LocationQuery } from "vue-router";
  import { useDisplay } from "vuetify";
  import ShowVerifyModalForCall from "./components/Shared/banners/ShowVerifyModalForCall.vue";
  import Logout from "./components/Shared/Logout.vue";
  import { useFreeLiveCallRedirection } from "./helpers/developer-program";
  import logger from "./helpers/logger";
  import { getIP } from "./helpers/platform";
  import OnboardingUpdate from "./modules/surveys/components/OnboardingUpdate.vue";
  import Survey from "./modules/surveys/components/Survey.vue";
  import SurveyToggleCard from "./modules/surveys/components/SurveyToggleCard.vue";
  import { useAuthStore } from "./stores/auth.store";
  import { useBusinessStore } from "./stores/businesses.store";
  import { useUtilsStore } from "./stores/utils.store";

  const businessStore = useBusinessStore();
  const authStore = useAuthStore();
  const freeLiveCallRedirection = useFreeLiveCallRedirection();

  const { smAndDown } = useDisplay();

  const route = useRoute();
  const utilsStore = useUtilsStore();

  const acceptedRoutes = [
    "/reset-password",
    "/verify-email/new",
    "/verify-email/existing",
    "/verify-signin-token",
    "/verify-nin",
  ];

  const onCloseLogoutModal = () => {
    utilsStore.handleToggleLogoutModal(false);
  };

  onMounted(() => {
    utilsStore.isMobile = smAndDown.value;
    void getIP(); // get IpAddress - use void to handle the promise
  });

  watch(
    // Watch the route for token to redirect free users
    () => route.query,
    async (to: LocationQuery) => {
      if (to.token && !acceptedRoutes.includes(route.path)) {
        try {
          await freeLiveCallRedirection.handleTokenRedirection(
            to.token.toString()
          );
        } catch (error) {
          logger.error(error, "Redirect from landing page");
        }
      }
    }
  );
</script>
