import { useUsersStore } from "@/stores/users.store";
import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import { NumberProvisionPayload } from "../types/numberProvision";
interface ResponseData {
  [key: string]: any;
}

const getUserId = () => {
  const userStore = useUsersStore();
  return userStore?.currentUserBusinessLevel?.id;
};

export const getAvailableNumbers = async (
  country_code: string,
  numberType?: string,
  isTollFree?: boolean,
  isSpecialNumber?: boolean
): Promise<ResponseData> => {
  const queryParams = new URLSearchParams({ country: country_code });

  if (numberType) queryParams.append("number_type", numberType);
  if (isTollFree !== undefined)
    queryParams.append("is_toll_free", String(isTollFree));
  if (isSpecialNumber !== undefined)
    queryParams.append("is_special_number", String(isSpecialNumber));

  queryParams.append("page_size", "100");

  return await requestClient.get(
    `${BASE_API}/api/available-numbers/?${queryParams.toString()}`
  );
};
export const provisionNumberToUser = async (
  payload: NumberProvisionPayload
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/telephony/provision-number/`,
    { json: payload }
  );
};

export const isCardExist = async (): Promise<ResponseData> => {
  return await requestClient.get(`${BASE_API}/api/users/${getUserId()}/cards/`);
};
