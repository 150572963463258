<template>
  <v-card class="d-flex rounded-lg justify-center align-center py-6 px-4" flat>
    <img width="64px" src="@/assets/Images/icons/profile/warning.svg" />
    <p class="mt-4 mb-3 nin-modal-title font-weight-bold">
      NIN Verification Required
    </p>
    <p class="nin-modal-subtitle px-5 mb-3 text-center">
      To continue making and receiving calls, please complete your NIN
      verification process
    </p>
    <v-btn
      class="filled-primary-color"
      height="48"
      block
      flat
      @click="utilStore.handleClick"
      >Verify NIN</v-btn
    >
    <v-btn
      height="40"
      color="primary"
      block
      variant="text"
      @click="utilStore.showVerifyModalForCall = false"
      >Do this later</v-btn
    >
  </v-card>
</template>
<script lang="ts" setup>
  import { useUtilsStore } from "@/stores/utils.store";

  defineEmits(["closeModal"]);

  const utilStore = useUtilsStore();
</script>
<style scoped>
  .close-modal {
    position: absolute;
    right: 16px;
    top: 17px;
  }
</style>
