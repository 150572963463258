<template>
  <div class="container">
    <v-dialog
      v-model="utilStore.showSurveyModal"
      max-width="400"
      transition="dialog-bottom-transition"
      persistent
      scrim="white"
      bottom
      class="d-flex justify-start align-bottom"
    >
      <v-card
        flat
        class="modal-card grey-dark py-6 px-5"
        style="margin-top: 300px"
      >
        <div class="d-flex justify-end align-center">
          <img
            src="@/assets/Images/close.svg"
            class="pointer"
            @click="closeModal"
          />
        </div>
        <div
          v-if="currentStep === 1"
          class="flex flex-column justify-center align-center mb-3"
        >
          <p class="question-text text-center mt-3 mb-5 mx-10">
            How do you feel about our service this week?
          </p>
          <div class="d-flex ga-3 align-center justify-center my-3">
            <div
              v-for="(emoji, i) in emojiQuestion"
              :key="i"
              class="emoji-container-div d-flex align-center justify-center mb-2 position-relative"
              @mouseleave="unhoverEmoji"
              @mouseover="hoverEmoji(emoji)"
              @click="goToNextQuestion(i)"
            >
              <img
                :src="emoji.src"
                :class="{
                  'selected-emoji': selectedEmoji?.name === emoji.name,
                  'hovered-emoji': hoveredEmoji?.name === emoji.name,
                }"
                class="emoji-image pointer"
                width="35"
                @click="toggleEmoji(emoji)"
              />

              <!-- Tooltip -->
              <div
                v-if="hoveredEmoji?.name === emoji.name"
                class="tooltip-container"
              >
                <div class="tooltip-content">
                  {{ emoji.name }}
                </div>
                <div class="tooltip-arrow"></div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="mx-5">
          <p class="question-text mt-2 text-center">
            How can we improve your experience for next week?
          </p>
          <v-textarea
            v-model="feedback"
            variant="solo"
            flat
            placeholder="Tell us about your experience"
            class="mt-3"
            rows="2"
          ></v-textarea>

          <div class="d-flex justify-end ga-2 mt-3">
            <v-btn
              class="filled-primary-color"
              height="40"
              :loading="isLoading"
              flat
              @click="submitForm"
              >Submit</v-btn
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSuccessModal"
      max-width="400"
      transition="dialog-bottom-transition"
      persistent
      class="d-flex justify-start align-bottom"
    >
      <v-card
        flat
        class="modal-card grey-dark py-6 px-5"
        style="margin-top: 300px"
      >
        <div class="d-flex justify-center">
          <v-icon class="ps-6" color="#34C759" size="60"
            >mdi-check-circle
          </v-icon>
        </div>
        <div class="text-center mt-4">
          <p class="success-title">
            Thank you for taking the time to share your experience on PressOne
          </p>
        </div>
        <v-btn
          class="filled-primary-color mt-9 mx-auto"
          flat
          height="40"
          width="100"
          @click="dismissFeedBack"
          >Dismiss</v-btn
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
  import logger from "@/helpers/logger";
  import { agentFeedback } from "@/helpers/queries/customerengagement";
  import { useUtilsStore } from "@/stores/utils.store";
  import { notify } from "@kyvg/vue3-notification";
  import { ref } from "vue";

  const utilStore = useUtilsStore();
  const showSuccessModal = ref(false);

  const selectedEmoji = ref<{
    name: string;
    color: string;
  } | null>(null);
  const currentStep = ref(1);
  const emojiQuestion = ref([
    {
      src: "/assets/Images/emojis/very-dissatisfied-active.png",
      name: "Very dissatisfied!",
      color: "#FF3B30",
    },
    {
      src: "/assets/Images/emojis/dissatisfied-active.png",
      name: "Dissatisfied",
      color: "#3D89DF",
    },
    {
      src: "/assets/Images/emojis/satisfied-active.png",
      name: "Indifferent",
      color: "#E7AE05",
    },
    {
      src: "/assets/Images/emojis/satisfied-emoji.png",
      name: "Satisfied",
      color: "#E7AE05",
    },
    {
      src: "/assets/Images/emojis/very-satisfied-active.png",
      name: "Very satisfied!",
      color: "#40B869",
    },
  ]);

  const toggleEmoji = (emoji: { name: string; color: string }) => {
    selectedEmoji.value = emoji;
  };

  const hoveredEmoji = ref<any>(null);
  const hoverEmoji = (emoji: any) => {
    // Set the active emoji index when hovering
    hoveredEmoji.value = emoji;
  };

  const unhoverEmoji = () => {
    // Clear the active emoji index when unhovering
    hoveredEmoji.value = null;
  };

  const ratings = ref(0);
  const feedback = ref("");
  const goToNextQuestion = (index: number) => {
    ratings.value = index + 1;
    currentStep.value = 2;
  };
  const submitForm = async () => {
    isLoading.value = true;
    try {
      await agentFeedback({
        rating: ratings.value,
        feedback: feedback.value,
      });
      showSuccessModal.value = true;
      utilStore.showSurveyModal = false;
    } catch (error: any) {
      logger.error(error, "Weekly Feedback");
      notify({
        text: `${error?.message || error}`,
        type: "error",
      });
    } finally {
      isLoading.value = false;
      utilStore.showSurveyModal = false;
      utilStore.showSurveyCard = false;
    }
  };
  const isLoading = ref<boolean>(false);

  const dismissFeedBack = () => {
    showSuccessModal.value = false;
    utilStore.showSurveyModal = false;
    utilStore.showSurveyCard = false;
  };
  const closeModal = () => {
    currentStep.value = 1;
    utilStore.terminateSurveyCheck();
  };
</script>
<style scoped>
  .container {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    z-index: 1000;
    bottom: 30px;
    right: 30px;
    width: 400px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow effect */
  }

  .image {
    width: 100%;
    height: 200px;
  }

  .content {
    width: 100%;
  }
  .feedback-title {
    font-size: 15px;
    font-weight: 600;
    line-height: 26.1px;
    letter-spacing: -0.02em;
    color: #1d2739;
  }
  .feedback-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #475367;
  }
  .feedback-share {
    font-size: 15px;
    font-weight: 600;
    line-height: 25.2px;
  }
  .question-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.6px;
    letter-spacing: -0.02em;
    text-align: left;
    color: #1d2739;
  }
  .yes-no-card {
    border: 1px solid #d0d5dd;
    border-radius: 200px !important;
    padding: 3px;
  }
  .selected-card {
    background: linear-gradient(0deg, #edfaff, #edfaff);
    border: none;
  }
  .selected-inner-card {
    border: 1px solid #21baf1;
    border-radius: 300px;
    width: 75px;
  }
  .reason-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    text-align: left;
    color: #101928;
  }
  .experience-rate {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    text-align: left;
    color: #475367;
  }
  .rate-value {
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    text-align: left;
    background: #f7f9fc;
  }
  .close-feedback {
    top: 10px;
    right: 10px;
  }
  .success-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 26.1px;
    text-align: center;
    color: #344054;
  }
  .success-subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.3px;
    text-align: center;
    color: #667185;
  }

  .emoji-container-div {
    width: 44px;
    height: 44px;
    background: #f7f9fc;
    border-radius: 50px;
  }
  .emoji-image:hover {
    width: 39px;
  }
  .v-text-field ::v-deep .v-field.v-field {
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    background: #f7f9fc;
  }
  .position-relative {
    position: relative;
  }

  .tooltip-container {
    position: absolute;
    top: 120%; /* Position the tooltip below the emoji */
    left: 50%;
    transform: translateX(-50%);
    background-color: #1d2739;
    color: #fff;
    padding: 10px 10px;
    border-radius: 8px;
    white-space: nowrap;
    z-index: 10;
    font-size: 12px;
  }

  .tooltip-arrow {
    position: absolute;
    top: -10px; /* Place the arrow on top of the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #1d2739 transparent; /* Creates an upward-facing arrow */
  }
</style>
