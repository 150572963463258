import { PressoneInstance } from "@/plugins/dialer";
import { useBusinessStore } from "@/stores/businesses.store";
import { useDialerStore } from "@/stores/dialer.store";
import { useNumberStore } from "@/stores/numbers.store";
import { useUtilsStore } from "@/stores/utils.store";
import { notify } from "@kyvg/vue3-notification";
import { UIState } from "@pressone/dialer/lib/types";
import { inject } from "vue";
import { useCallLogs } from "./calls";
import { trackAmplitudeEvent } from "./integrations/analytics/amplitude";
import { AMPLITUDE_EVENTS } from "./integrations/analytics/events";
import logger from "./logger";
import {
  formatNigerianNumber,
  getHangupEvent,
  isNigerianNumber,
} from "./utils";
// import { PressOne } from "@pressone/dialer";
import { sendUserActivityEvent } from "./integrations/analytics/backend-events";
import { INBOUND_CALL_EVENTS } from "./integrations/analytics/user-activity-events";
export function useConnectPhoneToSip() {
  const getPressoneInstance: any = inject("getPressoneInstance");
  const dialerStore = useDialerStore();
  const numberStore = useNumberStore();

  const reconnectPhone = async (id: any) => {
    // disable connection if the id passed to reconnect doesnt exist or have a valid credentials.
    // from the initial connection only numbers with valid creds from receiver line are sent to library to connect and stored in sipcredential in dialerstore.
    // Attempting to reconnect a number that isnt among this will throw a "Cannot destructure property 'domain' of 'undefined' as it is undefined."

    if (!dialerStore.sipCredentials?.hasOwnProperty(id)) return;
    dialerStore.isRegistered[id] = {
      ...dialerStore.isRegistered[id],
      isConnecting: true,
    };
    if (numberStore?.subscriptionStatus === "past_due") {
      notify({
        type: "error",
        text: "Account restricted due to outstanding payment",
      });
    } else {
      if (getPressoneInstance) {
        try {
          await getPressoneInstance().start(
            numberStore.currentUserReceiverKey?.toString(),
            [id]
          );

          getPressoneInstance().onRegistered = () => {
            dialerStore.isRegistered[id] = {
              isConnected: true,
              isConnecting: false,
            };
          };
          getPressoneInstance().onRegistrationFail = () => {
            dialerStore.isRegistered[id] = {
              isConnected: false,
              isConnecting: false,
            };
          };
          getPressoneInstance().onUnregistered = () => {
            dialerStore.isRegistered[id] = {
              isConnected: false,
              isConnecting: false,
            };
          };
        } catch (e) {
          logger.error(e, "Reconnect Phone");

          notify({
            text: `Encountered an error connecting number`,
            title: "Error connecting..",
            type: "error",
          });
          dialerStore.isRegistered[id] = {
            isConnected: false,
            isConnecting: false,
          };
        }
      }
    }
  };
  // Reconnect phone when there is a disconnection
  function reconnectPhoneAfterDisconnection() {
    if (!navigator.onLine) {
      notify({
        text: "You are currently not online at the moment. Kindly connect to the internet and retry",
        type: "error",
      });
      return;
    }
    if (dialerStore.isRegistered !== null) {
      for (const id in dialerStore.isRegistered) {
        if (!dialerStore.isRegistered[id].isConnected) {
          try {
            reconnectPhone(id);
          } catch (error) {
            logger.error(error, "Reconnect Phone");
          }
        }
      }
    }
  }

  function disconnectPhoneWhenOffline() {
    for (const id in dialerStore.isRegistered) {
      if (getPressoneInstance() && getPressoneInstance().state[id]) {
        getPressoneInstance().state[id].userAgent.stop();
        dialerStore.isRegistered[id].isConnected = false;
        dialerStore.isRegistered[id].isConnecting = false;
      }
    }
  }
  return {
    reconnectPhone,
    reconnectPhoneAfterDisconnection,
    disconnectPhoneWhenOffline,
  };
}
export const callNumber = async (
  number: string,
  pressoneInstance: PressoneInstance
) => {
  // revert back from any
  const numberStore = useNumberStore();
  const businessStore = useBusinessStore();
  const utilStore = useUtilsStore();
  const { displayVerificationModal } = useCallLogs();
  if (!numberStore.activeNumber) {
    notify({
      text: "To proceed with the call, please ensure that you have purchased a business number",
      type: "warn",
    });
    return;
  }
  if (
    // if pressone users are using international numbers to call it must be in country code format
    numberStore.activeNumber?.business_number?.country_code.toLowerCase() !==
    "ng"
  ) {
    if (!isNigerianNumber(number) && !number.startsWith("+")) {
      notify({
        text: "Please ensure you add (+) with your country code before dialing.",
        type: "error",
      });
      return;
    } else {
      number = formatNigerianNumber(number);
    }
  }
  if (
    !businessStore.activeProfile?.business?.verified &&
    utilStore.hasMadeACall
  ) {
    // check the total calls made for an unverified user and restrict
    displayVerificationModal();
    return;
  }
  try {
    pressoneInstance()?.call(
      number,
      undefined,
      undefined,
      numberStore.activeNumber.business_number.phone_number
    );
  } catch (error) {
    logger.error(error, "callNumber - dialer");
  }
};
const clearAllAudioChannel = (pressoneInstance: PressoneInstance) => {
  // end all audio streams
  for (const key in pressoneInstance().audioChannels) {
    const audioElement = document.createElement("audio");
    audioElement.src = pressoneInstance().audioChannels[key].currentSrc;
    if (audioElement.src === "https://outlinks.pressone.co/p0p") {
      if (!audioElement.paused) {
        audioElement.pause();
      }
      audioElement.src = "";
      audioElement.srcObject = null;
    }
  }
};
export const endCall = (pressoneInstance: PressoneInstance) => {
  const dialerStore = useDialerStore();
  try {
    dialerStore.isAnsweredButton = false;

    pressoneInstance().endCall();
    if (getHangupEvent(dialerStore.dialerState.callDurationFormatted))
      trackAmplitudeEvent(AMPLITUDE_EVENTS.HANGUP);
    else {
      if (dialerStore.callDirection.toLowerCase() === "outgoing")
        trackAmplitudeEvent(AMPLITUDE_EVENTS.HANGUP_BEFORE_SESSION_STARTS);
      else trackAmplitudeEvent(AMPLITUDE_EVENTS.HANGUP_REJECTED);
    }
  } catch (error) {
    logger.error(error, "End Call");
  } finally {
    dialerStore.showDialerModal = false; // close the dialer
    clearAllAudioChannel(pressoneInstance); // clear ringing in the channel
  }
};
export const answerCall = (pressoneInstance: PressoneInstance) => {
  try {
    const dialerStore = useDialerStore();

    dialerStore.isAnsweredButton = true;
    pressoneInstance().answer();
    sendUserActivityEvent(
      INBOUND_CALL_EVENTS.CALL_PICKUP_ATTEMPTED,
      dialerStore.getCallInfo
    );
  } catch (error) {
    logger.error(error, "Answer Call");
  }
};
export const holdCall = (
  dialerState: UIState,
  pressoneInstance: PressoneInstance
) => {
  try {
    if (!dialerState.showHoldButton) return;
    if (dialerState.isHeld)
      pressoneInstance()?.getOngoingCallSession()?.unhold();
    else {
      pressoneInstance()?.getOngoingCallSession()?.hold();
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CALL_HELD);
    }
  } catch (error) {
    logger.error(error, "Hold Call");
  }
};
export const muteCall = (
  dialerState: UIState,
  pressoneInstance: PressoneInstance
) => {
  try {
    if (!dialerState.showMuteButton) return;
    if (dialerState.isMuted)
      pressoneInstance()?.getOngoingCallSession()?.unmute();
    else {
      pressoneInstance()?.getOngoingCallSession()?.mute();
      trackAmplitudeEvent(AMPLITUDE_EVENTS.CALL_MUTED);
    }
  } catch (error) {
    logger.error(error, "Mute Call");
  }
};
export const transferCall = (
  pressoneInstance: PressoneInstance,
  transferTo: number
) => {
  try {
    pressoneInstance().blindTransfer(transferTo);
    trackAmplitudeEvent(AMPLITUDE_EVENTS.CALL_TRANSFERRED);
  } catch (error) {
    logger.error(error, "Transfer Call");
  }
};
export const playDTMF = (
  pressoneInstance: PressoneInstance,
  input: number | string
) => {
  try {
    pressoneInstance().playDTMF(input.toString());
  } catch (error) {
    logger.error(error, "Play DMTF");
  }
};
export const sendDTMF = (
  pressoneInstance: PressoneInstance,
  input: number | string
) => {
  try {
    pressoneInstance().sendDTMF(input.toString());
  } catch (error) {
    logger.error(error, "Send DMTF");
  }
};
export const callExtension = (
  ext: string,
  pressoneInstance: PressoneInstance
) => {
  const { currentUserReceiverKey, activeNumber } = useNumberStore();
  const options = {
    ...pressoneInstance().getDefaultCallOptions(),
  };
  useDialerStore().isExtension = true;
  if (currentUserReceiverKey)
    pressoneInstance()?.state[currentUserReceiverKey]?.userAgent?.call(
      `sip:${ext}@${activeNumber.line.domain}`,
      options
    );
};
