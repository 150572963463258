import { PINIA_PERSIST_OPTIONS } from "@/helpers/constants";
import {
  getUserDetailsByTokenNewImplementation,
  updateUserDetails,
} from "@/helpers/queries";
import { recommendationData, SignupData } from "@/helpers/types/global";
import { defineStore } from "pinia";

export interface CurrentUserBusinessLevel {
  number_of_invites: number;
  total_calls: number;
  verification: {
    status: string;
    verification_deadline: null;
    attempts_left: number;
  };
  profiles: Array<{
    business: {
      balance: number;
      free_trial_data: {
        live_call_limit: number;
        live_call_count: number;
        voice_otp_limit: number;
        voice_otp_count: number;
        free_trial_expired: boolean;
      };
      is_partner_customer: boolean;
      has_filled_engagement_form: boolean;
      id: number;
      name: string;
      business_timezone: string;
      email: string;
      address: string;
      owner: number;
      verified: boolean;
      is_go_global: boolean;
      billing_requires_attention: boolean;
      has_requirement_info_pending: boolean;
      has_provisioned_first_number: boolean;
    };
    last_active: string;
    mobile: string;
    is_active: boolean;
    has_filled_engagement_form: boolean;
    has_submitted_weekly_survey: boolean;
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    user: number;
    date_created: string;
  }>;
  country: string;
  business: Array<any>;
  last_active: string;
  free_trial_data: {
    is_free_trial_user: boolean;
    is_developer: boolean;
  };
  id: number;
  first_name: string;
  last_name: string;
  mobile: string;
  personal_email: string;
  profile_picture: null;
  invitation_code: null;
  verified: boolean;
  mobile_verified: boolean;
  email_verified: boolean;
  next_two_factor_verification: null;
  has_completed_signup: boolean;
  has_completed_number_flow: boolean;
  has_topped_up: boolean;
  has_received_app_install_reward: boolean;
  has_completed_feedback: boolean;
  onboarding_stage: null;
  has_filled_engagement_form: boolean;
  home_address: null;
  has_done_walkthrough: boolean;
}

interface ResponseData {
  [key: string]: any;
}
type Feedback = Record<
  string,
  {
    title: string;
    description: string;
    img: string;
    event: string;
  }
>;
export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    users: {},
    user_role: "",
    currentUser: null as ResponseData | null, // currentUser can be of type ResponseData or null
    currentUserBusinessLevel: null as ResponseData | null, // currentUser can be of type ResponseData or null
    registerUser: {
      id: "",
      first_name: "",
      last_name: "",
      mobile: "",
      personal_email: "",
    }, //user signup data
    registerBusiness: {} as recommendationData,
    recommended_plan: {},
    activeTab: 0, //for complete sign-up view
    permissions: {} as any,
    showFeedBackModal: true,
    NIN: "",
    hasFilledEngagement: false,
    savedSignupDetails: null as null | SignupData,
    userFeedbackModal: false,
    feedback: {
      callerGreeting: {
        title: "Rate your set up experience",
        description:
          "How would you rate the experience of setting up your first caller greeting. Your feedback would help us improve your experience.",
        img: "/assets/Images/feedback/greetings.png",
        event: "professional_greeting",
      },
    } as Feedback,
    currentFeedback: {} as
      | (Feedback[keyof Feedback] & {
          data?: Record<string, string>;
        })
      | null,
    isFetchingUserDetail: false,
  }),
  getters: {
    getAgentRole: (state) => {
      return state.permissions || {};
    },
    userProfile: (state) => {
      return state.currentUserBusinessLevel?.profiles;
    },
    businesses: (state) => {
      return state.currentUserBusinessLevel?.business;
    },
    canAccessManagerView: (state) => {
      return ["owner", "manager", "specialist"].includes(state.user_role);
    },
    canAccessAgentView: (state) => {
      return ["agent"].includes(state.user_role);
    },
  },
  actions: {
    async updateUser(payload: any) {
      await updateUserDetails(payload).then((res: any) => res.data);

      const currentUser = await getUserDetailsByTokenNewImplementation().then(
        (res: any) => res.data
      );

      this.currentUserBusinessLevel = currentUser;
    },

    async fetchUserDetails() {
      const currentUser = await getUserDetailsByTokenNewImplementation().then(
        (res: any) => res.data
      );
      this.currentUserBusinessLevel = currentUser;
    },
    setFeedBack(feature: string) {
      this.currentFeedback = this.feedback[feature];
    },
  },
  persist: PINIA_PERSIST_OPTIONS,
});
