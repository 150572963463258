import { useNumberAddressAddOnStore } from "@/stores/add-ons/number-address.store";
import { notify } from "@kyvg/vue3-notification";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import { useNumberStore } from "../stores";
import { cleanPayload } from "./callQueue";
import logger from "./logger";
import {
  cancelAddressRenewal,
  fetchGlobalAddress,
  fetchPurchasedAddress,
} from "./queries/add-ons/address-numbers";
import { objectToQueryString } from "./utils";

const pageSize = 100;

export const useAddress = () => {
  const numberAddressAddOnStore = useNumberAddressAddOnStore();

  const route = useRoute();

  const router = useRouter();

  const numberStore = useNumberStore();

  const { selectedAddress, addresses } = storeToRefs(numberAddressAddOnStore);

  const handleFetchAddress = async (
    payload: Record<string, string | number | boolean | null> = {}
  ) => {
    let query = {
      // state: stateCode.value,
      page_size: pageSize,
      ...payload,
    };
    // to remove empty values and keys
    query = cleanPayload(query);

    const queryString = objectToQueryString(query) as string;
    try {
      const response = await fetchGlobalAddress(queryString);
      return response;
    } catch (error) {
      logger.error(error, "Error Fetching global Address");
    }
  };

  const handleFetchBusinessAddresses = async (
    payload: Record<string, string | number | boolean | null> = {}
  ) => {
    let query = {
      // state: stateCode.value,
      page_size: 50,
      ...payload,
    };
    // to remove empty values and keys
    query = cleanPayload(query);

    const queryString = objectToQueryString(query) as string;
    try {
      const response = await fetchPurchasedAddress(queryString);
      addresses.value = response?.data?.data;
      handleAddressViewRedirect();
      return response?.data?.data;
    } catch (error) {
      logger.error(error, "Error Fetching purchased Address");
    }
  };

  const handleAddressViewRedirect = () => {
    // check if its an address only user so as to redirect
    // an address only user does not have a business number but only addresses
    if (
      !numberStore.allNumbersRegisteredUnderABusiness?.length &&
      !route.path.includes("address-user") &&
      !route.path.includes("additional-purchase") &&
      !route.path.includes("provision-number") &&
      addresses.value?.length
    ) {
      void router.push("/address-user");
      // redirect to the address only layout
    }
  };

  const handleCancelRenewal = async (id: string) => {
    try {
      const response = await cancelAddressRenewal(id);
      notify({
        type: "success",
        text: "Address renewal cancelled",
      });
      return response?.data?.data;
    } catch (error: any) {
      notify({
        type: "error",
        text:
          error?.message ||
          error?.detail ||
          "Error occured while cancelling renewal",
      });
    }
  };

  return {
    handleFetchAddress,
    selectedAddress,
    handleFetchBusinessAddresses,
    handleCancelRenewal,
  };
};
