import outgoing from "@/assets/Images/icons/calltype/call-outgoing.svg";
import { inject, ref } from "vue";
// import unanswered from '@/assets/Images/icons/calltype/unanswered.svg'
import incoming from "@/assets/Images/icons/calltype/call-incoming.svg";
import missed from "@/assets/Images/icons/calltype/call-missed.svg";
import {
  default as internal,
  default as voicemailcall,
} from "@/assets/Images/icons/calltype/call-outgoing.svg";
import transferred from "@/assets/Images/icons/calltype/call-transferred.svg";
import { useBusinessStore } from "@/stores/businesses.store";
import { useCallStore } from "@/stores/call.store";
import { useContactStore } from "@/stores/contact.store";
import { useDialerStore } from "@/stores/dialer.store";
import { useNumberStore } from "@/stores/numbers.store";
import { useUsersStore } from "@/stores/users.store";
import { useUtilsStore } from "@/stores/utils.store";
import { notify } from "@kyvg/vue3-notification";
import dayjs from "dayjs";
import { predefinedGradients } from "./constants";
import { callExtension, callNumber } from "./dialer";
import logger from "./logger";
import {
  ResponseData,
  exportCallHistory,
  fetchCallLogDetailsById,
  fetchIndividualCallLength,
  filterCallHistoryNew,
  getCallHistoryNew,
} from "./queries";
import {
  CallInfo,
  CallLogDetails,
  CallTypeFormatting,
  SortedData,
  allCallData,
} from "./types/call";
import { Contact } from "./types/contact";
import {
  formatNigerianNumber,
  formatPhoneNumberWithCountryCodeAndCountry,
  isNigerianNumber,
} from "./utils";

const callList = ref<ResponseData | null>(null);
const isLoading = ref(false);

export function useCallLogs() {
  const callStore = useCallStore();
  const contactStore = useContactStore();
  const dialerStore = useDialerStore();
  const numberStore = useNumberStore();
  const utilsStore = useUtilsStore();
  const businessStore = useBusinessStore();

  const getPressoneInstance: any = inject("getPressoneInstance");

  const callList = ref<allCallData | null>(null);
  const avatarGradient = ref<string>(
    "linear-gradient(180deg, #FF8268 0%, rgba(255, 173, 156, 0.76) 100%)"
  );

  const callContact = async (number: string): Promise<void> => {
    // revert back from any
    if (!number || number.length < 9) {
      notify({
        text: "Please enter a valid phone number",
        type: "error",
      });
      return;
    }
    if (numberStore.subscriptionStatus === "past_due") {
      notify({
        text: "Your line is not connected. Account restricted due to outstanding payment",
        type: "error",
      });
      return;
    }
    if (
      !dialerStore.isRegistered[numberStore?.currentUserReceiverKey!]
        ?.isConnected
    ) {
      notify({
        text: "Your line is not connected. Please check your internet connection and try again",
        type: "error",
      });
      return;
    }
    if (
      number.startsWith("+234") &&
      numberStore.activeNumber?.business_number?.country_code.toLowerCase() ===
        "ng"
    ) {
      number = "0" + number.slice(4);
    } else if (
      number.startsWith("234") &&
      numberStore.activeNumber?.business_number?.country_code.toLowerCase() ===
        "ng"
    ) {
      number = "0" + number.slice(3);
    }
    if (
      // if pressone users are using international numbers to call it must be in country code format
      numberStore.activeNumber?.business_number?.country_code.toLowerCase() !==
      "ng"
    ) {
      if (!isNigerianNumber(number) && !number.startsWith("+")) {
        notify({
          text: "Please ensure you add (+) with your country code before dialing.",
          type: "error",
        });
        return;
      } else {
        number = formatNigerianNumber(number);
      }
    }
    dialerStore.showOutboundModal = false;
    try {
      try {
        callNumber(number, getPressoneInstance);
      } catch (error) {
        notify({
          text: "Cannot make call",
          type: "error",
        });
      }
    } catch (error) {
      notify({
        text: "Enter a valid phone and start call",
        type: "error",
      });
    }
  };
  const cleanInput = () => {
    dialerStore.numberToCall = dialerStore.numberToCall.replace(/\s/g, "");
  };
  const getCallHistory = async (
    businessId?: string | number,
    source?: string
  ) => {
    const userStore = useUsersStore();
    isLoading.value = true;

    try {
      const getAllLogs = await listOfCallHistory(
        !userStore.canAccessManagerView ? "10" : "25",
        "",
        undefined,
        true,
        businessId,
        source
      );

      callStore.callList = getAllLogs;
      callStore.totalLogs = callStore.callList?.total as number;
      const callLogs: CallInfo[] = callStore.callList?.data || [];
      callStore.pageIndex = callStore.callList?.page_index;
      selectRow(callLogs[0], 0); // fetch call details of either the first or index passed
      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
    } finally {
      callStore.fetchFilter = false;
      isLoading.value = false;
    }
  };
  const getFilteredHistory = async (sortedData: SortedData) => {
    isLoading.value = true;
    try {
      const getFilteredLogs = await listOfFilteredCallHistory(sortedData);
      callStore.callList = getFilteredLogs;
      callStore.totalLogs = getFilteredLogs.total;
      const callLogs = callStore.callList?.data!;
      callStore.pageIndex = callStore.callList?.page_index;
      selectRow(callLogs[0], 0); // fetch call details of either the first or index passed
      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
    } finally {
      isLoading.value = false;
    }
  };
  const selectRow = async (
    selectedRow?: CallInfo,
    index?: number,
    gradient?: string
  ) => {
    if (gradient) avatarGradient.value = gradient;
    try {
      callStore.showCallNotesCard = false; // allow call note to unmount and remount to prevent caching

      if (selectedRow) {
        callStore.selectedCallInfo = selectedRow;
        callStore.selectedTeamMemberLog = callStore.selectedCallInfo.user;
      } // if details is fetched from logs update with available details. This could be called from activities stream also
      callStore.isFetchingDetails = true;
      callStore.selectedCallContactDetails =
        contactStore.savedContacts?.data?.find(
          (contact: Contact) =>
            contact.name === callStore.selectedCallInfo?.caller_name
        ) ?? {};
      callStore.successSave = false;
      if (index) callStore.selectedIndex = index;
      if (selectedRow && selectedRow.caller_id) {
        const response = await fetchCallLogDetailsById(selectedRow.sip_call_id);
        callStore.selectedCallInfo = { ...response.data?.data };
        callStore.showCallNotesCard = true; // allow call note to unmount and remount to prevent caching
      }
    } catch (error: any) {
      notify({
        text: `${error?.message || error}`,
        type: "error",
      });
    } finally {
      callStore.isFetchingDetails = false;
    }
  };
  const navigate = async (link: string, index?: number) => {
    if (link) {
      isLoading.value = true;
      try {
        const getAllLogs = await listOfCallHistory("", link);

        callStore.callList = getAllLogs;
        callStore.totalLogs = getAllLogs.total;

        // Check if results exist before accessing the first item
        if (callStore.callList?.data && callStore.callList?.data.length > 0) {
          const callLogs = callStore.callList?.data;
          callStore.pageIndex = callStore.callList?.page_index;
          selectRow(callLogs[index ? index : 0], index ? index : 0); // fetch call details of either the first or index passed
        }
        callStore.selectedCallContactDetails =
          contactStore.savedContacts?.data?.find(
            (contact: Contact) =>
              contact.name === callStore.selectedCallInfo?.caller_name
          ) ?? {};
        isLoading.value = false;
        callStore.successSave = false;
      } catch (error) {
        isLoading.value = false;
      }
    } else {
      return notify({
        text: "No more records available on the next page.",
        type: "error",
      });
    }
  };
  const numberInputRules = [
    (v: string) => !!v || "Phone number is required",
    (v: string) => {
      const strippedNumber = v.replace(/\s/g, ""); // Remove spaces from the input
      return (
        /^\+?\d+$/.test(strippedNumber) ||
        "Invalid Phonenumber. Input must be a valid number (allowing +)"
      );
    },
  ];
  const displayVerificationModal = () => {
    if (
      utilsStore.hasMadeACall &&
      !businessStore.activeProfile?.business?.verified
    ) {
      utilsStore.showVerifyModalForCall = true;
      return;
    }
    dialerStore.showOutboundModal = !dialerStore.showOutboundModal;
  };
  const callTeamMember = (ext: string) => {
    callExtension(ext, getPressoneInstance);
  };

  const generateNewNoteObject = () => {
    // create a new call note object if no notes exist
    return {
      note: "",
      call_id: callStore?.selectedCallInfo?.sip_call_id,
      contact_number: callStore?.selectedCallInfo?.is_dialed
        ? formatPhoneNumberWithCountryCodeAndCountry(
            callStore?.selectedCallInfo?.recipient_number
          ).formattedPhoneNumber
        : formatPhoneNumberWithCountryCodeAndCountry(
            callStore?.selectedCallInfo?.caller_id
          ).formattedPhoneNumber,
      business_number_id: numberStore?.activeNumber?.business_number.id,
      call_log_id: callStore?.selectedCallInfo?.id,
      date_created: new Date().toISOString(),
    };
  };
  const getGradient = (index: number) => {
    const gradientIndex =
      index < predefinedGradients.length ? index : index % 2;
    return predefinedGradients[gradientIndex];
  };
  return {
    getFilteredHistory,
    getCallHistory,
    isLoading,
    callList,
    selectRow,
    avatarGradient,
    navigate,
    callContact,
    cleanInput,
    numberInputRules,
    displayVerificationModal,
    callTeamMember,
    generateNewNoteObject,
    getGradient,
  };
}
export const listOfCallHistory = async (
  pageSize: string,
  link: string,
  agent_id?: number,
  showAllLogs?: boolean,
  businessId?: string | number,
  source?: string
) => {
  try {
    const requestLink = link ? link : "";
    const callListData = await getCallHistoryNew(
      pageSize,
      requestLink,
      agent_id,
      showAllLogs,
      businessId,
      source
    );
    callList.value = callListData;
    return callList.value?.data;
  } catch (error) {
    logger.error(error, "List Call History");
    return null;
  }
};
export const listOfFilteredCallHistory = async (filter: SortedData) => {
  try {
    callList.value = await filterCallHistoryNew(filter);
    return callList.value?.data;
  } catch (error) {
    logger.error(error, "List Filtered Call History");
    return null;
  }
};

export const exportCallLogs = async () => {
  try {
    await exportCallHistory();
  } catch (error) {
    logger.error(error, "Export Call Log");
  }
};
export function determineCallStatus( // get call type and icon
  is_dialed: boolean,
  is_forwarded_call: boolean,
  is_missed_call: boolean,
  has_voice_mail: boolean,
  has_recording?: boolean | null,
  recording?: string | null
): CallTypeFormatting {
  let displayType: CallTypeFormatting = {
    icon: "",
    color: "",
    type: "",
    textColor: "",
    fontColor: "",
  };
  if (is_dialed) {
    displayType = {
      icon: outgoing,
      color: "#ebfff0",
      type: "Outgoing",
      textColor: "#E3EFFC",
      fontColor: "#34C759",
    };
  }
  // if (is_dialed && !recording?.length) {
  //   displayType = {
  //     icon: unanswered,
  //     color: "#F7F9FC",
  //     type: "Unanswered",
  //     textColor: "#1D2739",
  //   };
  // }
  else if (!is_dialed && (has_recording || recording) && !is_missed_call) {
    if (!is_forwarded_call)
      displayType = {
        icon: incoming,
        color: "#E3EFFC",
        type: "Incoming",
        textColor: "#E3EFFC",
        fontColor: "#3D89DF",
      };
    else
      displayType = {
        icon: transferred,
        color: "#ebfff0",
        type: "Forwarded",
        textColor: "#E3EFFC",
      };
  } else if (!is_dialed && is_missed_call) {
    displayType = {
      icon: missed,
      color: "#FBEAE9",
      type: "Missed",
      textColor: "#E3EFFC",
      fontColor: "#FF3B30",
    };
  } else if (has_voice_mail) {
    displayType = {
      icon: voicemailcall,
      color: "#ebfff0",
      type: "Voicemail",
      textColor: "#E3EFFC",
    };
  } else if (is_dialed && has_voice_mail) {
    displayType = {
      icon: internal,
      color: "#ebfff0",
      type: "Internal",
      textColor: "#E3EFFC",
    };
  }
  return displayType;
}

const getCallTypeFormatting = (
  callLog: CallInfo | CallLogDetails
): CallTypeFormatting => {
  return determineCallStatus(
    callLog?.is_dialed,
    callLog?.is_forwarded_call,
    callLog?.is_missed_call,
    callLog?.has_voice_mail,
    callLog?.has_recording,
    callLog?.recording
  );
};
export const getCallStatusType = (
  callLog: CallInfo | CallLogDetails | null
): string => {
  const { type } = getCallTypeFormatting(callLog!);
  return type;
};

export const getCallStatusIcon = (callLog: CallInfo | null): string => {
  const { icon } = getCallTypeFormatting(callLog!);

  return icon;
};

export const getCallStatusBackgroundColor = (callLog: CallInfo): string => {
  const { color } = getCallTypeFormatting(callLog);
  return color;
};

export const getCallStatusTextColor = (callLog: CallInfo): string => {
  const { color } = getCallTypeFormatting(callLog);
  return color;
};
export const downloadAudio = (audioUrl: string) => {
  fetch(audioUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Recordings.wav";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
};

export const onNextPage = async (response: any) => {
  try {
    await listOfCallHistory("", response.next);
  } catch (error) {
    logger.error(error, "Next Call Log Page Pagination");
  }
};
export const onPrevPage = async (response: any) => {
  try {
    await listOfCallHistory("", response.previous);
  } catch (error) {
    //
    logger.error(error, "Previous Call Log Page Pagination");
  }
};
export const getMaxLimit = (value: number) => {
  let maxLimit = 0;
  switch (true) {
    case value >= 100 && value < 200:
      maxLimit = 100;
      break;
    case value >= 50 && value < 100:
      maxLimit = 50;
      break;
    case value >= 25 && value < 50:
      maxLimit = 25;
      break;
    default:
      maxLimit = 10;
  }
  return maxLimit;
};

export function getDateRange(period: string) {
  const today = dayjs();
  let fromDate, toDate;
  switch (period) {
    case "past_week":
      fromDate = today.subtract(6, "day").format("YYYY-MM-DD");
      break;
    case "past_month":
      fromDate = today
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      break;
    case "past_year":
      fromDate = today.subtract(1, "year").startOf("year").format("YYYY-MM-DD");
      break;
    default:
      throw new Error("Invalid period provided");
  }

  toDate = today.format("YYYY-MM-DD");
  return { from_date: fromDate, to_date: toDate };
}
export const fetchCallLogsForAgents = async () => {
  try {
    const utilsStore = useUtilsStore();
    const response = await fetchIndividualCallLength();
    utilsStore.totalCalls = response?.data?.total;
  } catch (error) {
    if (
      typeof error === "string" &&
      error.toString() !== "You do not have permission to perform this action."
    )
      logger.error(error, "Error Fetching Logs");
  }
};
