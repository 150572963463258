import { BASE_API } from "@/helpers/constants";
import requestClient from "../api/requestClient";

export const addNewCallNote = async (payload: any, useCallLogEndpoint: any) => {
  const withCallId = `${BASE_API}/api/telephony/call-notes/`;
  const withCallLog = `${BASE_API}/api/telephony/call-notes/with-log/`;

  return await requestClient.post(
    useCallLogEndpoint ? withCallLog : withCallId,
    { json: payload }
  );
};

export const updateCallNote = async (payload: any, pk: any) => {
  return await requestClient.patch(
    `${BASE_API}/api/telephony/call-notes/id/${pk}/`,
    { json: payload }
  );
};
export const addNewCallNoteToActiveCall = async (payload: any) => {
  const withCallId = `${BASE_API}/api/telephony/call-notes/`;

  return await requestClient.post(withCallId, { json: payload });
};

// New note endpoint

export const addCallNoteList = async (businessId: string, payload: any) => {
  const withCallLog = `${BASE_API}/api/business/${businessId}/call-note/`;
  return await requestClient.post(withCallLog, { json: payload });
};
export const fetchCallNoteList = async (
  call_log_id: string,
  businessId: string
) => {
  return await requestClient.get(
    `${BASE_API}/api/business/${businessId}/call-note/?call_log_id=${call_log_id}`
  );
};
// New note endpoint
