import "./assets/css/iconsax/iconsax-icon/icons.css";

import Notifications from "@kyvg/vue3-notification";
import * as Sentry from "@sentry/vue";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";
import App from "./App.vue";
import {
  BASE_API,
  PROD_SENTRY_DSN,
  STAGING_SENTRY_DSN,
} from "./helpers/constants";
import logger from "./helpers/logger";
import vuetify from "./plugins/vuetify";
// @eslint-disable-next-line Do not change the order of the imports, it breaks vuetify styles
import "./assets/css/main.scss";
import "./assets/css/variable.scss";
import { customerIoPlugin } from "./plugins/customerIo";
import router from "./router";
import { useAuthStore } from "./stores/auth.store";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(VueQueryPlugin);
app.use(vuetify);
app.use(router);
app.use(Notifications);
app.use(customerIoPlugin);

const authStore = useAuthStore();

if (authStore.isAuthenticated) {
  import("./plugins/dialer")
    .then((module) => {
      app.use(module.dialerPlugin);
    })
    .catch((error) => {
      logger.error(error, "Failed to load dialer plugin");
    });
}

if (import.meta.env.MODE !== "development") {
  try {
    Sentry.init({
      app,
      dsn:
        BASE_API === "https://api.pressone.co"
          ? PROD_SENTRY_DSN
          : STAGING_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      ignoreErrors: [
        "Unhandled Promise Rejection: Connect aborted.",
        "Unhandled Promise Rejection: Disconnect aborted.",
        "Unhandled Promise Rejection: WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
        "Uncaught Error: Registerer terminated. Unable to register.",
        "Unhandled Promise Rejection: Peer connection undefined.",
        "Registerer terminated. Unable to register.",
        "Connect aborted",
        "WebSocket closed wss://fs1.pressone.co:7443 (code: 1006)",
        "Unhandled Promise Rejection: REGISTER request already in progress, waiting for final response",
        "REGISTER request already in progress, waiting for final response",
        "Unhandled Promise Rejection: Invalid state transition from Stopped to Stopped",
        "Failed to fetch dynamically imported module",

        // This usually happens due to user network issues
        /^error loading dynamically imported module:\s*(.*)/i,

        // These are errors outside the vue app, e.g in node_modules like surreal websocket
        /(?:Error:\s*)?Object captured as exception with keys:\s*(.*)/,
        /(?:UnhandledRejection:\s*)?Object captured as promise rejection with keys:\s*(.*)/,
        /(?:UnhandledRejection:\s*)?Non-Error promise rejection captured with value:\s*(.*)/,
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["staging-new-pressone-web.netlify.app"], // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  } catch (e) {
    logger.error(e, "Sentry init error");
  }
}

app.mount("#app");
