import router from "@/router";
import {
  useBusinessStore,
  useNumberStore,
  useUsersStore,
  useUtilsStore,
} from "@/stores";
import { getPermissions, handleFetchUserDetails } from "./auth";
import { getSubscriptionStatus } from "./billings";
import { getPendingNumberPayment } from "./queries/onboarding";

export const fetchUnbundledUserAndBusinessDetails = async () => {
  const utilStore = useUtilsStore();
  const businessStore = useBusinessStore();
  const numberStore = useNumberStore();
  const userStore = useUsersStore();

  userStore.isFetchingUserDetail = true;

  const pendingSetupResponse = await getPendingNumberPayment();
  const pendingNumberSetup = pendingSetupResponse.data;
  utilStore.setSetUpPayload(pendingNumberSetup?.data);
  if (
    (!utilStore.pendingSetupPayload?.length &&
      !businessStore.businesses?.length) || // now redirects only when there is no business. cos a manager can have a business and not have a number
    !businessStore.businesses
  ) {
    void router.push("/complete-signup");
  }
  if (utilStore.pendingSetupPayload?.length) {
    void router.push("/managers/provision-number");
  }
  if (
    userStore.canAccessAgentView &&
    !businessStore?.activeProfile?.has_filled_engagement_form
  ) {
    void router.push("/agents/engagement");
  }
  await handleFetchUserDetails();

  // fetch list of numbers under a business
  await numberStore.getUserPhonesFromBusiness();
  userStore.permissions = await getPermissions();

  const status = await getSubscriptionStatus();
  numberStore.subscriptionStatus = status;
  userStore.isFetchingUserDetail = false;
};
