import { useNumberStore } from "@/stores/numbers.store";
import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import { getActiveBusinessId } from "../queries";
import {
  BulkTeamMemberInvitePayload,
  BuyAdditionalNumberPayload,
  InviteTeamMemberPayload,
  PatchTeamMember,
} from "../types/teams";

const getReceiverNumberId = () => {
  const numberStore = useNumberStore();
  return numberStore.activeNumber?.business_number.id;
};
interface ResponseData {
  [key: string]: any;
}

export const fetchTeamMembers = async (
  nextUrl?: string
): Promise<ResponseData> => {
  const currentUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/user/?page_size=100`;
  return await requestClient.get(`${nextUrl ? nextUrl : currentUrl}`);
};

export const fetchAllTeamMembers = async (): Promise<ResponseData> => {
  const currentUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/user/?page_index=1&page_size=100`;
  return await requestClient.get(currentUrl);
};

export const deleteTeamMember = async (
  userId: number
): Promise<ResponseData> => {
  return await requestClient.delete(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/`
  );
};

export const inviteTeamMember = async (
  payload: InviteTeamMemberPayload
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/`,
    { json: payload }
  );
};

export const bulkInviteTeamMember = async (
  payload: BulkTeamMemberInvitePayload
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/bulk/`,
    { json: payload }
  );
};

export const fetchPendingInvites = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/receiver_numbers/get_pending_invitations/`
  );
};

export const updateTeamMemberProfile = async (
  payload: PatchTeamMember,
  userId: number
): Promise<ResponseData> => {
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/profile/`,
    { json: payload }
  );
};

export const updateTeamMemberPermission = async (
  payload: { business_numbers: string[]; [key: string]: boolean | string[] },
  userId: number
): Promise<ResponseData> => {
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/permission/`,
    { json: payload }
  );
};

export const revokeTeamMemberInvite = async (
  payload: number | string
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/receiver_numbers/${payload}/revoke_invitation/`
  );
};

export const fetchTeamMembersStatus = async (
  payload: any
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/numbers/${getActiveBusinessId()}/team-members-status/`,
    { json: payload }
  );
};

export const buyAdditionalNumberExtensions = async (
  payload: BuyAdditionalNumberPayload
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/buy_additional_number_extensions/`,
    { json: payload }
  );
};

export const buyAdditionalNumberExtensionsForExisting = async (
  payload: BuyAdditionalNumberPayload
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/v2/buy_additional_number_extensions/charge_card_authorization/`,
    { json: payload }
  );
};

export const exportTeamMembersCSV = async () => {
  return await requestClient.download(
    `${BASE_API}/api/receiver_numbers/export-csv/?business_number=${getReceiverNumberId()}`,
    { blobType: "text/csv" },
    "team-members.csv"
  );
};

export const fetchPermissions = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/permission/`
  );
};

export const fetchTeamMemberDetails = async (
  userId: number
): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${userId}/`
  );
};

export const updateSessionActivity = async (): Promise<ResponseData> => {
  return await requestClient.patch(`${BASE_API}/api/user/session-activity/`);
};
