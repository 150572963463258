import { useNumberStore } from "@/stores/numbers.store";
import { useTeamStore } from "@/stores/team-member.store";
import { notify } from "@kyvg/vue3-notification";
import type { Ref } from "vue";
import { ref } from "vue";
import logger from "./logger";
import { addContactToQueue, getCallQueues } from "./queries/callQueue";
import {
  deleteTeamMember,
  exportTeamMembersCSV,
  fetchAllTeamMembers,
  fetchTeamMembers,
  fetchTeamMembersStatus,
} from "./queries/teamMembers";
import type { allCallData } from "./types/call";
import { AddContactToQueuePayload } from "./types/callQueue";
import {
  InviteTeamMemberPayload,
  SingleTeamMemberPayload,
} from "./types/teams";

export const useTeamMember = {
  teamMemberCallQueue: ref([]) as Ref<any[]>,

  allCallQueues: ref([]) as Ref<any[]>,

  teamMemberCallRecord: ref([]),

  teamMembersWithStatus: ref() as any,

  callQueueLoading: ref(false),

  teamMemberLoading: ref(false),

  creationDate: ref(""),

  teamMembersStatus: ref() as any,

  callRecordLoading: ref(false),

  extraInfo: ref(),

  callList: ref<allCallData | null>(null),

  listTeamMembers: async (nextUrl?: string) => {
    const teamStore = useTeamStore();
    useTeamMember.teamMemberLoading = ref(true);
    try {
      const teamMembersList = await fetchTeamMembers(nextUrl);
      const { total, page_size, page_index, nb_pages, previous, next } =
        teamMembersList.data;
      useTeamMember.extraInfo.value = {
        total,
        page_size,
        page_index,
        nb_pages,
        previous,
        next,
      };
      teamStore.teamMembers = teamMembersList.data.data;
      // useTeamMember.fetchTeamStatus();
      useTeamMember.teamMemberLoading = ref(false);
      return teamMembersList.data.data;
    } catch (error: any) {
      useTeamMember.teamMemberLoading = ref(false);
      logger.error(error, "Error fetching team members");
    }
  },
  teamMembersList: async () => {
    const teamStore = useTeamStore();
    useTeamMember.teamMemberLoading = ref(true);
    try {
      const teamMemberList = await fetchAllTeamMembers();

      teamStore.teamMembersList = teamMemberList.data.data;

      return teamMemberList.data.data;
    } catch (error: any) {
      logger.error(error, "Error fetching all team members");
    }
  },
  handleDeleteTeamMembers: async (id: number) => {
    try {
      await deleteTeamMember(id);
    } catch (error: any) {
      notify({
        text:
          error?.message ||
          error?.detail ||
          "An error occured while deleting team member",
        type: "error",
      });
    }
  },
  handleGetUsersCallQueue: async () => {
    useTeamMember.callQueueLoading.value = true;

    try {
      const response = await getCallQueues();

      useTeamMember.allCallQueues.value = response.data.data;
      // useTeamMember.creationDate.value = response?.data.date_created;
      // useTeamMember.teamMemberCallQueue.value = response.data;
    } catch (error) {
      logger.error(error, "Error get call queue");
    }
    useTeamMember.callQueueLoading.value = false;
  },

  handleAddContactToQueue: async (
    payload: AddContactToQueuePayload,
    agent_id: number
  ) => {
    try {
      await addContactToQueue(payload, agent_id);
    } catch (error: any) {
      notify({
        type: "error",
        text:
          error?.message ||
          error?.detail ||
          "An error occured while adding contact to queue",
      });
    }
  },
  fetchTeamStatus: async () => {
    // fetch status and map with members
    const teamStore = useTeamStore();

    const numberStore = useNumberStore();
    if (
      numberStore.activeNumber?.receiver_role?.toLowerCase() !== "user" &&
      teamStore.teamMembers?.length
    ) {
      // if members exist
      try {
        //  get extensions
        const teamExtensions = teamStore.teamMembers.map((ext: any) =>
          String(ext.extension_code)
        ); // map paginated data cos only 20 extensions can be sent at once
        const teamStatusPayload = {
          extensions: teamExtensions,
        };
        // get the status
        const response = await fetchTeamMembersStatus(teamStatusPayload);

        const teamMembersStatus = response.data;
        // map the status with member array.
        useTeamMember.teamMembersWithStatus.value =
          useTeamMember.updateUsersStatus(
            teamStore.teamMembers,
            teamMembersStatus
          );

        teamStore.teamMembers = useTeamMember.teamMembersWithStatus.value;
      } catch (error) {
        logger.error(error, "fetchTeamMembersStatus");
      }
    }
  },

  updateUsersStatus: (members: any, extensionStatus: any) => {
    return members.map((entry: any) => {
      const extensionCode = entry.extension_code.toString(); // Convert to string for comparison

      if (extensionStatus.hasOwnProperty(extensionCode)) {
        entry.status = extensionStatus[extensionCode];
      }
      return entry;
    });
  },

  handleTeamMemberExportCSV: async () => {
    try {
      await exportTeamMembersCSV();
    } catch (error) {
      logger.error(error, "exportTeamMembersCSV");
    }
  },
  handlePrevious: async () => {
    useTeamMember.listTeamMembers(useTeamMember.extraInfo.value.previous);
  },

  handleNext: async () => {
    useTeamMember.listTeamMembers(useTeamMember.extraInfo.value.next);
  },
};

export function getModifiedKeysAndValues(obj1: any, obj2: any) {
  const modifiedKeysAndValues = {} as any;

  for (const key in obj1) {
    if (
      obj1.hasOwnProperty(key) &&
      obj2.hasOwnProperty(key) &&
      obj1[key] !== obj2[key]
    ) {
      modifiedKeysAndValues[key] = obj2[key];
    }
  }

  return modifiedKeysAndValues;
}

// New Team Member Invite flow, Invite multiple team members

export const currentStep = ref(1);

export const memberRoleOptions = ref([
  { title: "Call Agent", value: "agent" },
  { title: "Manager", value: "manager" },
  { title: "Business Owner", value: "owner" },
]);

export const usePhoneNumber = ref(false);

export function generateInvitationPayload(data: InviteTeamMemberPayload) {
  return data.emailList.map((email: string) => ({
    email: email,
    role: data.role,
    permissions: {
      ...data.permissions,
      business_numbers: data.selectedNumbers,
      role: data.role,
    },
    can_make_calls: true, // Assuming all emails can make calls
    country: "NG", // Assuming "NG" as the default country
  })) as SingleTeamMemberPayload[];
}
