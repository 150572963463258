// import { CallScript } from "./@/helpers/queries/callScript";
// src/router/index.ts
import { allowedRoutesOnMobileScreen } from "@/helpers/allowedRoutesOnMobile";
import {
  freeTrialRoutes,
  noAuthRoutes,
  publicPages,
} from "@/helpers/constants";
import openReplay from "@/helpers/openReplay";
import Layout from "@/layouts/Layout.vue";
import { loadZohoScript, removeZohoScript } from "@/plugins/zohoDesk";
import { useAddonStore } from "@/stores/add-ons/index.store";
import { useLiveCallWidgetStore } from "@/stores/add-ons/live-widget.store";
import { useNumberAddressAddOnStore } from "@/stores/add-ons/number-address.store";
import { useAuthStore } from "@/stores/auth.store";
import { useBusinessStore } from "@/stores/businesses.store";
import { useNumberStore } from "@/stores/numbers.store";
import { useTeamStore } from "@/stores/team-member.store";
import { useUsersStore } from "@/stores/users.store";
import { useUtilsStore } from "@/stores/utils.store";
import { notify } from "@kyvg/vue3-notification";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

const RootView = () => import("@/views/RootView.vue");
const AgentLayout = () => import("@/layouts/AgentLayout.vue");
const ManagerLayout = () => import("@/layouts/ManagerLayout.vue");
const ConnectionView = () =>
  import("@/modules/developer-program/views/ConnectionView.vue");
const DeveloperProfile = () =>
  import("@/modules/developer-program/views/DeveloperProfile.vue");
const WelcomePage = () =>
  import("@/modules/developer-program/views/WelcomePage.vue");
const CallerGreetings = () =>
  import(
    "@/modules/personalization/components/IncomingCallFlow/MobileViewCallerGreetings.vue"
  );

const Dashboard = () => import("@/views/DashboardView.vue");
const Contact = () => import("@/views/ContactView.vue");
const ManagerCallRecords = () =>
  import("@/modules/callRecords/RecordsView.vue");
const CallRecordDetailsView = () =>
  import("@/modules/callRecords/CallRecordDetailsView.vue");
const ManagerContact = () => import("@/modules/contact/ManagerContact.vue");
const Signin = () => import("@/views/SigninView.vue");

const Otp = () => import("@/views/OtpView.vue");
const ForgotPassword = () => import("@/views/ForgotPasswordView.vue");
const EmailVerificationView = () => import("@/views/EmailVerificationView.vue");
const MagicLinkView = () => import("@/views/MagicLinkView.vue");
const ResetPasswordEmailVerificationView = () =>
  import("@/views/ResetEmailVerificationView.vue");
const VerifyEmailView = () => import("@/views/VerifyEmailView.vue");
const ResetPassword = () => import("@/views/ResetPasswordView.vue");
const CallRecords = () => import("@/modules/callRecords/AgentCallRecords.vue");
const Profile = () => import("@/modules/userProfile/AgentProfile.vue");
const Reports = () => import("@/views/ReportsView.vue");
const Report = () => import("@/views/report/index.vue");
const BillingHome = () => import("@/views/billing/index.vue");
const Billing = () => import("@/views/billing/Billing.vue");
const Activities = () => import("@/views/ActivityView.vue");
const Signup = () => import("@/views/SignupView.vue");
const CompleteSignup = () =>
  import("@/modules/signup/views/CompleteSignupProcess.vue");
const AgentSignUp = () => import("@/views/AgentSignupView.vue");
const Integrations = () =>
  import("@/modules/integrations/IntegrationsView.vue");
const DynamicView1 = () =>
  import("@/modules/integrations/views/dynamicView1.vue");
const ChromeExtension = () =>
  import("@/modules/integrations/views/ChromeExtension.vue");
const ZohoPhoneBridge = () =>
  import("@/modules/integrations/views/ZohoPhonebridge.vue");
const Zapier = () => import("@/modules/integrations/views/Zapier.vue");
// const PressoneWidget = () =>
//     import("@/modules/integrations/views/PressoneWidget.vue");
const IntegrationsList = () =>
  import("@/modules/integrations/IntegrationsList.vue");

const CallBack = () => import("@/components/CallBack.vue");

// const Activities = () => import("@/views/ActivityView.vue");
const UserOnboardingStatus = () =>
  import("@/modules/personalization/views/UserOnboardingStatusView.vue");
// const InitialUserOnboardingView = () =>
//   import(
//     "@/modules/personalization/views/UserOnboardingStatusInitialView.vue"
//   );
const OutStandingPaymentPage = () =>
  import("@/views/OutstandingPaymentPage.vue");
const NumberProvision = () =>
  import("@/modules/numberProvisioning/NumberProvisionView.vue");

const AdditionalPurchase = () =>
  import(
    "@/modules/numberProvisioning/AdditionalNumberPurchase/AdditionalNumber.vue"
  );

// const NumberProvision = () => import("@/views/ManagerPortal/NumberProvisionView.vue");
// const ActivateNumber = () => import("@/views/ManagerPortal/ActivateNumberView.vue");

const TeamView = () => import("@/modules/teamMembers/view/TeamView.vue");
const ManageTags = () => import("@/modules/tags/view/ManageTags.vue");
const ManagersProfile = () =>
  import("@/modules/userProfile/ManagerProfile.vue");
const LazyAffiliateView = () => import("@/views/AffiliateView.vue");
// Personalization
const AddNewCallFlow = () =>
  import("@/modules/personalization/views/AddNewCallFlow.vue");
const IncomingCallFlow = () =>
  import("@/modules/personalization/views/IncomingCallFlow.vue");

const MusicOnHold = () =>
  import("@/modules/music-on-hold/views/MusicOnHoldView.vue");

const MenuOption = () =>
  import(
    "@/modules/personalization/components/MenuOptions/MenuOptionsList.vue"
  );
// const CustomerEngagement = () => import("@/views/CustomerEngagement.vue");
// const AgentCustomerEngagement = () =>
//     import("@/views/AgentCustomerEngagement.vue");

const CallScript = () => import("@/modules/callScript/CallScriptView.vue");
const ConnectionManager = () =>
  import("@/modules/connection/ConnectionView.vue");
const VideoDemo = () => import("@/modules/video-demos/VideoDemoView.vue");
const AddOnList = () => import("@/modules/add-ons/index.vue");
const AddOns = () => import("@/modules/add-ons/components/AddOnLayout.vue");
const KnowledgeDomainAddon = () =>
  import("@/modules/add-ons/knowledge-base/KnowledgeBase.vue");
const VoiceOtpAddon = () =>
  import("@/modules/add-ons/voice-otp/views/VoiceOtp.vue");
const TextToSpeechAddon = () =>
  import("@/modules/add-ons/text-to-speech/views/TextToSpeech.vue");

const AddressAddonView = () =>
  import("@/modules/add-ons/address/views/AddressAddonView.vue");

const SalesCampaignAddon = () =>
  import("@/modules/add-ons/sales-campaign/views/SalesCampaign.vue");
const CampaignOverview = () =>
  import("@/modules/add-ons/sales-campaign/views/CampaignOverview.vue");
const AgentCampaign = () =>
  import("@/modules/add-ons/sales-campaign/views/AgentSalesCampaign.vue");
const AgentCampaignOverview = () =>
  import("@/modules/add-ons/sales-campaign/views/AgentCampaignOverview.vue");

const LiveCallOnly = () =>
  import(
    "@/modules/add-ons/live-call-widget/views/live-call-only/LiveCallOnlyView.vue"
  );
const UpgradeSubscribe = () =>
  import(
    "@/modules/add-ons/live-call-widget/views/live-call-only/UpgradeSubscribe.vue"
  );
const LiveCallOnlyRegistration = () =>
  import(
    "@/modules/add-ons/live-call-widget/views/live-call-only/Registeration.vue"
  );
const LiveCallWidget = () =>
  import("@/modules/add-ons/live-call-widget/views/LiveCallWidget.vue");

const LiveCallWelcome = () =>
  import("@/modules/add-ons/live-call-widget/views/live-call-only/Welcome.vue");
const AddOnOnlyLayout = () =>
  import("@/modules/add-ons/components/AddOnOnlyLayout.vue");

// Developer Program Layout
const DeveloperProgram = () => import("@/layouts/DeveloperProgramLayout.vue");
const DeveloperDashboard = () =>
  import("@/modules/developer-program/views/Dashboard.vue");
const Conversations = () =>
  import("@/modules/conversations/ConversationsView.vue");
const AgentConversations = () =>
  import("@/modules/conversations/AgentConversationView.vue");
const JoinConversation = () =>
  import("@/modules/conversations/JoinConversation.vue");
const GuestConversation = () =>
  import("@/modules/conversations/guestMode/index.vue");

// const AddonCollections = () =>
//     import("@/modules/developer-program/views/AddOn.vue");

// Developer Program Layout
const PostOnboarding = () =>
  import("@/modules/post-onboarding/view/ManagerOnboarding.vue");

const AgentPostOnboarding = () =>
  import("@/modules/post-onboarding/view/AgentOnboarding.vue");

// go-global routes
const NumberSelectionView = () =>
  import("@/modules/add-ons/address/views/NumberSelectionView.vue");

const AddressSelectionView = () =>
  import("@/modules/add-ons/address/views/AddressSelectionView.vue");

const PlanSelectionView = () =>
  import("@/modules/add-ons/address/views/PlanSelection.vue");

const SetupView = () => import("@/modules/add-ons/address/views/SetupView.vue");

const CartView = () => import("@/modules/add-ons/address/views/CartView.vue");

const PurchaseSuccessfulView = () =>
  import("@/modules/add-ons/address/views/PurchaseSuccessfulView.vue");

const AddressOnlyLayout = () => import("@/layouts/AddressOnlyLayout.vue");

const ThirdPartyVerificationView = () =>
  import("../../src/modules/userProfile/components/VerificationModals.vue");

const OnboardingTypeForm = () =>
  import("../../src/modules/personalization/views/OnboardingTypeForm.vue");

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Root",
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    component: Layout,
    children: [
      {
        path: "",
        component: RootView,
        name: "RootView",
      },
      {
        path: "/sign-in",
        name: "Signin",
        component: Signin,
      },

      {
        path: "/otp",
        name: "Otp",
        component: Otp,
      },

      {
        path: "/magic-link",
        name: "MagicLink",
        component: MagicLinkView,
      },
      {
        path: "/sign-up",
        name: "Signup",
        component: Signup,
      },
      {
        path: "/complete-signup",
        name: "CompleteSignup",
        component: CompleteSignup,
      },
      {
        path: "/outstanding-invoice-payment",
        name: "OutStandingPaymentPage",
        component: OutStandingPaymentPage,
      },
      {
        path: "/callback/:integrationSource",
        name: "CallBack",
        component: CallBack,
      },

      {
        path: "/verify-email/:type",
        name: "VerifyEmail",
        component: VerifyEmailView,
      },
      {
        path: "/verify-signin-token",
        name: "MagicLink",
        component: MagicLinkView,
      },
      {
        path: "/email-verification",
        name: "EmailVerification",
        component: EmailVerificationView,
      },
      {
        path: "/reset/email-verification",
        name: "ResetEmailverification",
        component: ResetPasswordEmailVerificationView,
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
      },
      {
        path: "/home",
        redirect: (to) => {
          if (to.query.trxref && to.query.reference) {
            return "/email-verification";
          }
          return "/";
        },
      },
      {
        path: "/affiliate",
        name: "AffiliateView",
        component: LazyAffiliateView,
      },

      {
        path: "/welcome",
        name: "WelcomePage",
        component: WelcomePage,
      },
      {
        path: "/number-selection",
        name: "NumberPurchase",
        component: NumberSelectionView,
      },
      {
        path: "/address-selection",
        name: "AddressSelectionView",
        component: AddressSelectionView,
      },
      {
        path: "/plan-selection",
        name: "PlanSelectionView",
        component: PlanSelectionView,
      },
      {
        path: "/business-setup",
        name: "SetupView",
        component: SetupView,
      },
      {
        path: "/purchase-cart",
        name: "CartView",
        component: CartView,
      },
      {
        path: "/purchase-successful",
        name: "PurchaseSuccessful",
        component: PurchaseSuccessfulView,
      },
      {
        path: "/verify-nin",
        name: "ThirdPartyVerificationView",
        component: ThirdPartyVerificationView,
      },
      {
        path: "/call-record-details",
        name: "CallRecordDetailsView",
        component: CallRecordDetailsView,
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/typeform",
        name: "OnboardingTypeForm",
        component: OnboardingTypeForm,
      },
      {
        path: "/agent/signup",
        name: "AgentSignUp",
        component: AgentSignUp,
      },

      {
        path: "/join-conversation",
        name: "JoinConversation",
        component: JoinConversation,
      },
      {
        path: "/guest-conversation",
        name: "GuestConversation",
        component: GuestConversation,
      },

      // Personalization
      {
        path: "/affiliate",
        name: "AffiliateView",
        component: LazyAffiliateView,
      },

      {
        path: "/welcome",
        name: "WelcomePage",
        component: WelcomePage,
      },
      {
        path: "/agents",
        name: "AgentLayout",
        component: AgentLayout,
        meta: { loadZoho: true },
        children: [
          {
            path: "",
            name: "Dashboard",
            component: Dashboard,
          },
          {
            path: "profile-update",
            name: "AgentProfile",
            component: Profile,
          },
          {
            path: "contact",
            name: "Contact",
            component: Contact,
          },
          {
            path: "call-records",
            name: "Call Records",
            component: CallRecords,
          },

          {
            path: "reports",
            name: "Reports",
            component: Reports,
          },
          {
            path: "conversations",
            name: "AgentConversations",
            component: AgentConversations,
          },
          {
            path: "demo-videos",
            name: "AgentVideoDemo",
            component: VideoDemo,
          },
          {
            path: "engagement",
            name: "AgentPostOnboarding",
            component: AgentPostOnboarding,
            meta: { layout: false },
          },
          {
            path: "connection-status",
            name: "AgentConnection",
            component: ConnectionManager,
          },
          {
            path: "campaigns",
            name: "AgentCampaign",
            component: AgentCampaign,
          },
          {
            path: "campaigns/overview",
            name: "AgentCampaignOverview",
            component: AgentCampaignOverview,
          },
        ],
      },

      // ManagerLayout
      {
        path: "/managers",
        name: "ManagerLayout",
        component: ManagerLayout,
        meta: { loadZoho: true },
        children: [
          {
            path: "activities",
            name: "Activities",
            component: Activities,
          },
          {
            path: "contact",
            name: "ManagerContact",
            component: ManagerContact,
          },
          {
            path: "billing",
            name: "BillingBaseView",
            component: BillingHome,
          },
          {
            path: "billing/:id/:plan",
            name: "Billing Details",
            component: Billing,
          },
          {
            path: "reports",
            name: "Report",
            component: Report,
          },
          {
            path: "call-records",
            name: "ManagerCallRecords",
            component: ManagerCallRecords,
          },
          {
            path: "incoming-flow",
            name: "IncomingCallFlow",
            component: IncomingCallFlow,
          },
          {
            path: "music-on-hold",
            name: "MusicOnHold",
            component: MusicOnHold,
          },
          {
            path: "manage-tags",
            name: "ManageTags",
            component: ManageTags,
          },

          {
            path: "call-script",
            name: "CallScript",
            component: CallScript,
          },
          {
            path: "user-onboarding-status",
            name: "UserOnboardingStatus",
            component: UserOnboardingStatus,
          },
          {
            path: "demo-videos",
            name: "VideoDemo",
            component: VideoDemo,
          },
          {
            path: "initial-user-onboarding",
            name: "InitialUserOnboardingView",
            // component: InitialUserOnboardingView,
            component: Activities,
          },
          {
            path: "team",
            name: "TeamView",
            component: TeamView,
          },
          {
            path: "profile-update",
            name: "ManagersProfile",
            component: ManagersProfile,
          },
          // Personalization
          {
            path: "add-new-call-flow",
            name: "AddNewCallFlow",
            component: AddNewCallFlow,
          },
          {
            path: "edit-call-flow/:id",
            name: "EditCallFlow",
            component: AddNewCallFlow,
          },
          {
            path: "connection-status",
            name: "ConnectionManager",
            component: ConnectionManager,
          },
          {
            path: "menu-option",
            name: "MenuOption",
            component: MenuOption,
          },
          {
            path: "conversations",
            name: "ConversationsView",
            component: Conversations,
          },

          {
            path: "provision-number",
            name: "NumberProvision",
            component: NumberProvision,
            meta: { layout: false },
          },
          {
            path: "engagement",
            name: "PostOnboarding",
            component: PostOnboarding,
            meta: { layout: false },
          },
          {
            path: "caller-greeting",
            name: "CallerGreetings",
            component: CallerGreetings,
            meta: { layout: false },
          },
          {
            path: "additional-purchase",
            name: "AdditionalPurchase",
            component: AdditionalPurchase,
            meta: { layout: false },
          },
          // integrate
          {
            path: "integrations",
            name: "Integrations",
            component: Integrations,
            children: [
              {
                path: "",
                name: "IntegrationsList",
                component: IntegrationsList,
              },
              {
                path: "chrome-extension",
                name: "ChromeExtension",
                component: ChromeExtension,
              },
              {
                path: "zoho-phonebridge",
                name: "ZohoPhoneBridge",
                component: ZohoPhoneBridge,
              },
              {
                path: "zapier",
                name: "Zapier",
                component: Zapier,
              },
              // {
              //     path: "pressone-widget",
              //     name: "PressoneWidget",
              //     component: PressoneWidget,
              // },
              {
                path: ":id",
                name: "DynamicView1",
                component: DynamicView1,
                beforeEnter: (to) => {
                  const allowedIntegrationRoutes = [
                    "hubspot-crm",
                    "intercom",
                    "pipe-drive",
                    "fresh-desk",
                    "zoho-crm",
                  ];
                  const params = to?.params?.id as string;
                  if (!allowedIntegrationRoutes.includes(params)) {
                    return false;
                  }
                  return;
                },
              },
            ],
          },
          {
            path: "add-ons",
            name: "AddOns",
            component: AddOns,
            children: [
              {
                path: "list",
                name: "AddOnsList",
                component: AddOnList,
              },
              {
                path: "knowledge-base",
                name: "knowledgeBaseAddon",
                component: KnowledgeDomainAddon,
              },
              {
                path: "live-call-widget",
                name: "LiveCallWidget",
                component: LiveCallWidget,
              },
              {
                path: "voice-otp",
                name: "VoiceOtpAddon",
                component: VoiceOtpAddon,
              },
              {
                path: "text-to-speech",
                name: "TextToSpeechAddon",
                component: TextToSpeechAddon,
              },
              {
                path: "address",
                name: "ManagerAddressAddon",
                component: AddressAddonView,
              },
              {
                path: "campaign",
                name: "SalesCampaignAddon",
                component: SalesCampaignAddon,
              },
              {
                component: CampaignOverview,
                path: "campaign/overview",
                name: "CampaignOverview",
              },
            ],
          },
        ],
      },
      // Addon only and free trial layout same layout may be used for subsequent free trial add-ons
      {
        path: "/add-on",
        name: "AddOnOnlyLayout",
        component: AddOnOnlyLayout,
        children: [
          {
            path: "free-live-call",
            name: "LiveCallOnly",
            component: LiveCallOnly,
          },

          {
            path: "live-call-free-widget/register-website",
            name: "LiveCallOnlyRegistration",
            component: LiveCallOnlyRegistration,
          },
          {
            path: "live-call-free-widget/welcome",
            name: "LiveCallWelcome",
            component: LiveCallWelcome,
          },
          {
            path: "live-call-free-widget/upgrade-subscription",
            name: "UpgradeSubscribe",
            component: UpgradeSubscribe,
          },
        ],
      },
      // Addon only and free trial layout

      // Start Developer Program routes
      {
        path: "/developers",
        name: "DeveloperProgram",
        component: DeveloperProgram,
        children: [
          {
            path: "dashboard",
            name: "DeveloperDashboard",
            component: DeveloperDashboard,
          },
          {
            path: "live-call-widget",
            name: "DeveloperLiveCallWidget",
            component: LiveCallWidget,
          },
          // Start Developer Program routes
          {
            path: "voice-otp",
            name: "DeveloperVoiceOtpAddon",
            component: VoiceOtpAddon,
          },
          // Start Developer Program routes
          {
            path: "profile-update",
            name: "DeveloperProfile",
            component: DeveloperProfile,
          },
          {
            path: "live-call-free-widget/upgrade-subscription",
            name: "DevUpgradeSubscribe",
            component: UpgradeSubscribe,
          },
          {
            path: "connection",
            name: "Connections",
            component: ConnectionView,
          },
        ],
      },
      // End Developer Program routes

      // start address only layout routes
      {
        path: "/address-user",
        name: "AddressOnlyLayout",
        component: AddressOnlyLayout,
        children: [
          {
            path: "",
            name: "AddressAddon",
            component: AddressAddonView,
          },
          {
            path: "billing",
            name: "Billing",
            component: BillingHome,
          },
          {
            path: "profile-update",
            name: "Profile",
            component: ManagersProfile,
          },
        ],
      },
    ],
  },

  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const utilsStore = useUtilsStore();
    const teamStore = useTeamStore();
    const authStore = useAuthStore();
    const userStore = useUsersStore();
    const numberStore = useNumberStore();
    const addonStore = useAddonStore();
    const businessStore = useBusinessStore();
    const liveCallWidgetStore = useLiveCallWidgetStore();
    const numberAddressStore = useNumberAddressAddOnStore();
    const utilStore = useUtilsStore();

    // redirect to login page if not logged in and trying to access a restricted page
    const paramsPath = to.fullPath.split("?");

    if (paramsPath.length > 0) {
      const params = new URLSearchParams(paramsPath[1]);
      // Display notification
      if (params.get("trxref") && params.get("reference")) {
        if (utilsStore.goGlobalPayment) {
          return "/purchase-successful";
        }

        if (teamStore.pendingInvite) {
          return "/managers/team?pending_draft=true";
        }

        // add this to check if it has redirected before to prevent infinite loop.
        if (!params.get("redirected")) {
          notify({
            text: "Payment successful",
            type: "success",
          });

          authStore.couponCode = "";
          params.set("redirected", "true"); // set to true to prevent it from redirecting in a loop
          return `/sign-in?${params.toString()}`;
        } // redirect to otp from here
      }
    }

    if (to.fullPath.includes("/purchase-successful")) {
      /// redirects if it was not a go global payment
      if (!utilsStore.goGlobalPayment) {
        return "/";
      }
    }

    const authRequired = !publicPages.includes(to.path);

    // Check if token exists in local storage
    const token = localStorage.getItem("token");

    if (token && !authStore.isAuthenticated) {
      authStore.token = token;
    }

    const includesPublicRoute = (route: string) => to.fullPath.includes(route);

    if (noAuthRoutes.some(includesPublicRoute)) {
      return;
    }

    if (authRequired && !authStore.isAuthenticated) {
      authStore.returnUrl = to.fullPath;

      return "/sign-in";
    }

    if (authStore.isAuthenticated && publicPages.includes(to.path)) {
      const hasAccounts =
        !!userStore.currentUserBusinessLevel?.profiles?.length;

      if (!hasAccounts) {
        return;
      }

      if (userStore.canAccessManagerView) {
        return "/managers/activities";
      }

      return "/";
    }

    // prevent navigation to routes that aren't responsive yet
    if (userStore.canAccessManagerView) {
      if (
        !allowedRoutesOnMobileScreen.includes(to.path) &&
        utilStore.isMobile
      ) {
        utilStore.showMobileModal = true;
        utilStore.restrictedLink = to.path;
        // redirect to call-record on refresh screen
        if (from.path === "/" || to.path === "/managers/activities") {
          return "/managers/call-records";
        }

        return false;
      }
    }

    // this checks if the only adddon available is the address addon
    // const isAddressAddon =
    //   addonStore.subscribedAddons[0]?.subscription_plan?.title
    //     .toLowerCase()
    //     .includes("address") && addonStore.subscribedAddons?.length == 1;

    // this checks if its a go global customer with just the address addon

    // Also enables some vital routes to be accessible
    if (
      !numberStore.allNumbersRegisteredUnderABusiness?.length &&
      !to.path.includes("address-user") &&
      !to.path.includes("additional-purchase") &&
      !to.path.includes("provision-number") &&
      numberAddressStore.addresses.length
    ) {
      // redirect to the address only layout
      return "/address-user";
    }

    if (to.fullPath.includes("/managers/")) {
      if (!userStore.canAccessManagerView) {
        return "/";
      }
    }

    // check agent role and redirect to the right page '/' redirects to agent home page
    if (to.fullPath.includes("/agents/")) {
      if (userStore?.canAccessManagerView) {
        return "/managers/activities";
      }
      if (!userStore?.canAccessAgentView) {
        return "/";
      }
    }

    // restriction for users trying to access the live call page with validation
    if (to.fullPath.includes("/live-call-widget")) {
      if (
        liveCallWidgetStore.hasAccess &&
        businessStore.activeProfile?.business.free_trial_data.free_trial_expired
      ) {
        const sourceRoute = userStore.currentUserBusinessLevel?.free_trial_data
          ?.is_developer
          ? "/developers/dashboard"
          : "/managers/add-ons/live-call-widget";

        return sourceRoute;
      }

      if (to.fullPath.includes("/knowledge-base")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
          (addon: any) =>
            addon.title.includes("Knowledge Base Addon") && addon.isPurchased
        );

        if (!hasAccess) {
          return "/manager/activities";
        }
      }

      if (to.fullPath.includes("/voice-otp")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
          (addon: any) =>
            (addon.title.includes("Voice OTP Addon") && addon.isPurchased) ||
            userStore.currentUserBusinessLevel?.free_trial_data
              .is_free_trial_user
        );

        if (!hasAccess) {
          return "/manager/activities";
        }
      }

      if (to.fullPath.includes("/text-to-speech")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
          (addon: any) =>
            addon.title.includes("Text To Speech Addon") && addon.isPurchased
        );

        if (!hasAccess) {
          return "/manager/activities";
        }
      }

      if (to.fullPath.includes("/campaign")) {
        const hasAccess = addonStore.getAllEnhancedAddons.some(
          (addon: any) =>
            addon.title.includes("Sales Campaign Addon") && addon.isPurchased
        );

        if (!hasAccess) {
          return "/manager/activities";
        }
      }

      // openReplay.start();
      // Permissions redirection // hide the module from the sidebar but also redirect for users that want to bypass it
      if (to.fullPath.includes("agents/reports")) {
        /// redirects when isnt owner or permissions isnt true
        if (!userStore.getAgentRole.can_view_activity_report) {
          return "/agents/";
        }
      }

      // Managers
      if (to.fullPath.includes("managers/user-onboarding-status")) {
        /// redirects when isnt owner or permissions isnt true
        if (!userStore.getAgentRole.can_manage_personalization) {
          return "/managers/activities";
        }
      }

      if (to.fullPath.includes("managers/billing")) {
        /// redirects whenpermissions isnt true
        if (!userStore.getAgentRole.can_manage_billing) {
          return "/managers/activities";
        }

        const sourceRoute = userStore.currentUserBusinessLevel?.free_trial_data
          ?.is_developer
          ? "/developers/live-call-free-widget/"
          : "/add-on/live-call-free-widget/";

        return `${sourceRoute}upgrade-subscription`;
      }

      if (
        !liveCallWidgetStore.hasAccess &&
        !userStore.currentUserBusinessLevel?.free_trial_data?.is_free_trial_user // redirect this user to dashboard to main page if no access and no free trial
      ) {
        return "/managers/activities";
      }
    }

    // NB if a free trial user tries to access a path not allowed
    if (
      userStore.currentUserBusinessLevel?.free_trial_data?.is_free_trial_user &&
      !freeTrialRoutes.includes(to.path)
    ) {
      const sourceRoute = userStore.currentUserBusinessLevel?.free_trial_data
        ?.is_developer
        ? "/developers/dashboard"
        : "/managers/add-ons/live-call-widget";

      return sourceRoute;
    }

    if (to.fullPath.includes("/knowledge-base")) {
      const hasAccess = addonStore.getAllEnhancedAddons.some(
        (addon: any) =>
          addon.title.includes("Knowledge Base Addon") && addon.isPurchased
      );

      if (!hasAccess) {
        return "/manager/activities";
      }
    }

    if (to.fullPath.includes("/voice-otp")) {
      const hasAccess = addonStore.getAllEnhancedAddons.some(
        (addon: any) =>
          (addon.title.includes("Voice OTP Addon") && addon.isPurchased) ||
          userStore.currentUserBusinessLevel?.free_trial_data.is_free_trial_user
      );

      if (!hasAccess) {
        return "/manager/activities";
      }
    }

    if (to.fullPath.includes("/text-to-speech")) {
      const hasAccess = addonStore.getAllEnhancedAddons.some(
        (addon: any) =>
          (addon.title.includes("Text To Speech Addon") && addon.isPurchased) ||
          userStore.currentUserBusinessLevel?.free_trial_data.is_free_trial_user
      );

      if (!hasAccess) {
        return "/manager/activities";
      }
    }

    if (
      to.fullPath.includes("managers/integrations") ||
      to.fullPath.includes("callback")
    ) {
      /// redirects when user not allowed to view integrations
      if (!numberStore.showIntegration) {
        return "/managers/activities";
      }
    }
    if (
      to.fullPath.includes("managers/manage-tags") ||
      to.fullPath.includes("managers/incoming-flow") ||
      to.fullPath.includes("managers/call-script")
    ) {
      /// redirects when  permissions isnt true
      if (!userStore.getAgentRole.can_manage_personalization) {
        return "/managers/activities";
      }
    }

    if (to.fullPath.includes("agents/engagement")) {
      /// redirects when this form has been filled to main page
      if (userStore.currentUserBusinessLevel?.has_filled_engagement_form) {
        return "/agents/";
      }
    }

    const loadZoho = to.matched.some((record) => record.meta.loadZoho);

    // Zoho Desk for specific routes
    if (loadZoho) {
      loadZohoScript();
    } else {
      removeZohoScript();
    }

    if (import.meta.env.MODE !== "development") {
      if (userStore.currentUserBusinessLevel) {
        openReplay.setUserID(
          `${userStore.currentUserBusinessLevel?.personal_email}`
        );
      }

      await openReplay.start();
    }
  }
);

export default router;
