import { notify } from "@kyvg/vue3-notification";
import { ref } from "vue";
import logger from "./logger";
import {
  getAvailableNumbers,
  provisionNumberToUser,
} from "./queries/numberProvision";
import { NumberProvisionPayload } from "./types/numberProvision";
export const getIconSrc = (color: string) => {
  return `/assets/Images/icons/bullets/${color}.svg`;
};
export const colors = ["greeen", "purple", "orange"];
export const getBounceClass = (color: string) => {
  return `bounce-${color}`;
};

export const companySizeVariant = [
  // {name: 'Select Business Size', value: ''},
  { name: "5 employees", value: "Below 10" },
  { name: "5 - 20 employees", value: "Above 10" },
  { name: "50 - 100 employees", value: "Above 50" },
  { name: "100 - 500 employees", value: "Above 100" },
  { name: "500 - 1000 employees", value: "Above 500" },
  { name: "1000 employees", value: "Above 1000" },
];

export const purposeVariant = [
  // {name: 'Select Business Size', value: ''},
  { name: "As Primary Business line", value: "As Primary Business line" },
  { name: "For Customer Service", value: "For Customer Service" },
  { name: "For Sales", value: "For Sales" },
  { name: "For Other Purposes.", value: "For Other Purposes." },
];

export const listOfAvailableNumberForProvisioning = async (
  countrycode: string,
  // number type could be optional since available business number list
  // can be mixture of both mobile and landline in initial signup
  numberType?: string,
  isTollFree?: boolean,
  isSpecialNumber?: boolean
) => {
  try {
    const numbers = await getAvailableNumbers(
      countrycode,
      numberType,
      isTollFree,
      isSpecialNumber
    );

    return { numbers: numbers.data };
  } catch (error) {}
};

export const provisionNumber = async (payload: NumberProvisionPayload) => {
  try {
    await provisionNumberToUser(payload);
  } catch (error: any) {
    notify({
      type: "error",
      text: `${error?.message || error}`,
    });
    logger.error(error, "Error thrown from provsion number helper");
    throw error;
  }
};
export const emailRule = [
  (v: string) => !!v || "Email is required",
  (v: string) => /.+@.+\..+/.test(v) || "Email must be valid",
];
export const nameRules = [(v: string) => !!v || "Business name is required"];

export const firstNameRule = [
  (v: string) => !!v || "First name is required",
  (v: string) => (!/\s/.test(v) ? !!v : "First name must be a valid name"),
];

export const tagNameRule = [
  (v: string) => !!v || "Tag name is required",
  (v: string) =>
    v.length <= 16 ? !!v : "Tag name must not exceeed 16 characters",
];

export const lastNameRule = [
  (v: string) => !!v || "Last name is required",
  (v: string) => (!/\s/.test(v) ? !!v : "Last name must be a valid name"),
];

export const NINRule = [
  (v: string) => !!v || "NIN IS required",
  (v: string) => (v.length == 11 ? !!v : "NIN number must be 11 digits"),
];

export const businessNumberRule = [
  (v: string) => !!v || "Business number is required",
];

export const businessNameRule = [
  (v: string) => !!v || "Business name is required",
];

export const sizeRule = [(v: string) => !!v || "Business size is required"];
export const purposeRule = [
  (v: string) => !!v || "Business number is required",
];
export const countryRule = [
  (v: string) => !!v || "Country type number is required",
];

export const numberProvisionRedirectUrl = ref("/managers/activities");

export const phoneNumberRule = (value: string, dialCode: string) => {
  let regexPattern: RegExp | undefined;

  switch (dialCode) {
    case "+234": // Nigeria
      regexPattern = /^(0?[0-9][0-9]\d{8})$/;
      break;
    case "+1": // United States
      regexPattern = /^[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/;
      break;
    case "+254": // Kenya
      regexPattern = /^([17]\d{8})$/;
      break;
    default:
      regexPattern = /^(0?[0-9][0-9]\d{8})$/; // Invalid dial code
  }
  if (regexPattern.test(value)) {
    return !!value;
  } else return "Phone number must be a valid phone number";
};
