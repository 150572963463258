import { PINIA_PERSIST_OPTIONS } from "@/helpers/constants";
import logger from "@/helpers/logger";
import {
  getConversationItems,
  getConversationItemsForGuest,
  getConversationNotes,
  getConversations,
} from "@/helpers/queries/conversations";
import {
  Conversation,
  ConversationItem,
  ConversationNote,
  CreatedBy,
} from "@/helpers/types/conversations";
import { defineStore } from "pinia";
import { watch } from "vue";

interface ConversationState {
  conversations: Conversation[];
  newConversationTitle: string | null;
  showNewConversationModal: boolean;
  showMergeConversationModal: boolean;
  mergeConversationId: string;
  isConversationEmpty: boolean;
  showConversationDetails: boolean;
  isConversationCallOngoing: boolean;
  isConversationCallEnded: boolean;
  isLoadingConversations?: boolean;
  ongoingConversationCallId: string | null;
  showInviteParticipantsModal: boolean;
  showCallParticipants: boolean;
  showDeadlineCalender: boolean;
  selectedChatId: string | null;
  guestToken: string;
  invitationId: string;
  invitedGuestId: string;
  isChatDisabled: boolean;
  filter_option: string;
  selectedChat: {
    id: string;
    title: string;
    description?: string;
    participants: any;
    contacts: any;
    time?: string;
    status: string;
    deadline: string;
    type: string;
    customer_mobile: string;
    customer_name: string;
    created_by: CreatedBy | null;
  };
  messages: null;
  conversationItemList: ConversationItem[];
  conversationNoteList: ConversationNote[];
  showConversationRoom: boolean;
  showCurrentConversationDetailsDrawer: boolean;
}

export const participant = {
  id: 0,
  first_name: "",
  last_name: "",
  mobile: "",
  personal_email: "",
  profile_picture: null,
};

export const contact = {
  id: "",
  name: "",
  number: "",
};

export const created_by = {
  name: "",
  id: "",
  mobile: "",
  profile_picture: null,
  personal_email: "",
};

export const useConversationStore = defineStore({
  id: "queue",
  state: (): ConversationState => ({
    conversations: [] as Array<Conversation>,
    newConversationTitle: null,
    showNewConversationModal: false,
    showMergeConversationModal: false,
    mergeConversationId: "",
    showInviteParticipantsModal: false,
    showCallParticipants: false,
    isConversationEmpty: false,
    isConversationCallOngoing: false,
    isConversationCallEnded: false,
    isLoadingConversations: false,
    ongoingConversationCallId: "",
    showConversationDetails: false,
    showDeadlineCalender: false,
    selectedChatId: null,
    guestToken: "",
    invitationId: "",
    invitedGuestId: "",
    isChatDisabled: false,
    filter_option: "",
    selectedChat: {
      id: "",
      title: "",
      description: "",
      participants: [],
      contacts: [],
      time: "",
      status: "",
      deadline: "",
      type: "",
      customer_mobile: "",
      customer_name: "",
      created_by: {
        id: 0,
        mobile: "",
        profile_picture: null,
        name: "",
        personal_email: "",
      },
    },
    messages: null,
    conversationItemList: [] as Array<ConversationItem>,
    conversationNoteList: [] as Array<ConversationNote>,
    showConversationRoom: false,
    showCurrentConversationDetailsDrawer: false,
  }),
  getters: {
    chatDisabled: (state) => {
      return state.selectedChat.status === "closed";
    },
  },
  actions: {
    async getConversationList(filterOption?: string, search?: string) {
      this.isLoadingConversations = true;
      try {
        const response = await getConversations(filterOption, search);
        if (response?.data?.data) {
          this.conversations = response.data.data;
          this.isConversationEmpty = response.data.data.length ? false : true;
        } else {
          this.conversations = [];
          this.isConversationEmpty = true;
        }
      } catch (error) {
        logger.error(error, "Error fetching conversations");
      } finally {
        this.isLoadingConversations = false;
      }
    },
    async getNotes() {
      const response = await getConversationNotes(this.selectedChatId);
      return response.data?.data;
    },

    async getConversationItemList(isGuest?: boolean) {
      const response = !isGuest
        ? await getConversationItems(this.selectedChatId)
        : await getConversationItemsForGuest(
            this.selectedChatId,
            this.invitationId,
            this.guestToken
          );

      if (window.location.href.includes("guest-conversation")) {
        if (response) {
          this.conversationItemList = response.data?.sort((a: any, b: any) => {
            const dateA = new Date(a.date_created); // Replace 'timestamp' with the actual property name
            const dateB = new Date(b.date_created); // Replace 'timestamp' with the actual property name
            return dateA.getTime() - dateB.getTime(); // Sort in ascending order (earliest first)
          });
        }
      } else {
        if (response.data?.data) {
          this.conversationItemList = response.data.data.sort(
            (a: any, b: any) => {
              const dateA = new Date(a.date_created); // Replace 'timestamp' with the actual property name
              const dateB = new Date(b.date_created); // Replace 'timestamp' with the actual property name
              return dateA.getTime() - dateB.getTime(); // Sort in ascending order (earliest first)
            }
          );
        }
      }
    },
    selectChat(chatId: string | undefined) {
      const selectedChat = this.conversations.find(
        (conversation) => conversation.id === chatId
      );

      if (selectedChat) {
        this.selectedChat = selectedChat;
        this.selectedChatId = selectedChat.id;
        this.isChatDisabled = selectedChat.status === "closed" ? true : false;
      } else {
        this.isChatDisabled = true;
      }
    },
    updateSelectedChat(chat: any) {
      this.isChatDisabled = chat.status === "closed";
    },
    closeConversation() {
      this.selectedChat = {
        id: "",
        title: "",
        description: "",
        participants: [participant],
        contacts: [contact],

        time: "",
        status: "",
        deadline: "",
        type: "",
        customer_mobile: "",
        customer_name: "",
        created_by: {
          id: 0,
          mobile: "",
          profile_picture: null,
          name: "",
          personal_email: "",
        },
      };
      this.selectedChatId = null;
      this.showConversationDetails = false;
    },
  },
  persist: PINIA_PERSIST_OPTIONS,
});

export function useConversationStoreSetup() {
  const conversationStore = useConversationStore();
  watch(
    () => conversationStore.selectedChat,
    (newChat) => {
      if (newChat) {
        conversationStore.isChatDisabled = newChat.status === "closed";
      }
    }
  );
}
