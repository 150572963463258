import logger from "@/helpers/logger";
import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import { getCurrentInstance } from "vue";

export function useCustomerIO() {
  const instance = getCurrentInstance();

  // Access the global property if we're in a component context
  if (instance) {
    return instance.appContext.config.globalProperties
      .$cioanalytics as AnalyticsBrowser;
  }

  // Fallback for when used outside of component context
  // This creates a new instance, but it's better to use the global one when possible
  logger.warn(
    "useCustomerIO() called outside of component context, creating new instance"
  );

  return AnalyticsBrowser.load(
    {
      cdnURL: "https://cdp-eu.customer.io",
      writeKey: import.meta.env.VITE_CUSTOMER_IO_WRITE_KEY,
    },
    {
      integrations: {
        "Customer.io In-App Plugin": {
          siteId: import.meta.env.VITE_CUSTOMER_IO_SITE_ID,
          // @ts-expect-error - This is a workaround to avoid type errors
          events: function (event: { type: string }) {
            switch (event.type) {
              case "in-app:message-opened":
                // do something when a message is opened
                break;
              case "in-app:message-dismissed":
                // do something when a message is dismissed
                break;
              case "in-app:message-action":
                // do something when a message is interacted with
                break;
              case "in-app:message-error":
                // do something when a message errors
                break;
            }
          },
        },
      },
    }
  );
}
