<template>
  <div
    class="d-flex align-center ga-2 feedback-div px-6 py-3 text-white pointer"
  >
    <img src="@/assets/Images/icons/feedbackstar.svg" /><span
      >Leave a feedback</span
    >
  </div>
</template>
<style scoped>
  .feedback-div {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20.3px;
    letter-spacing: -0.005em;
    text-align: center;
    background: #0d5eba;
    border-radius: 8px 8px 0 0;
  }
</style>
