<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
  import { useCustomerIO } from "@/composables/useCustomerIO";
  import { fetchUnbundledUserAndBusinessDetails } from "@/helpers/business";
  import logger from "@/helpers/logger";
  import { useAuthStore } from "@/stores/auth.store";
  import { useUsersStore } from "@/stores/users.store";
  import { onMounted, ref, watchEffect } from "vue";
  import { useRouter } from "vue-router";

  const authStore = useAuthStore();
  const userStore = useUsersStore();

  const analytics = useCustomerIO();

  const isIdentified = ref(false);

  const router = useRouter();

  onMounted(() => {
    router.afterEach(async (to) => {
      await analytics.page(to.name?.toString() ?? "");
    });
    void (async () => {
      if (authStore?.isAuthenticated && userStore?.businesses?.length) {
        await fetchUnbundledUserAndBusinessDetails();
      }
    })();
  });

  watchEffect(() => {
    void (async () => {
      if (!authStore.isAuthenticated || isIdentified.value) {
        return;
      }

      if (!userStore.currentUserBusinessLevel) {
        logger.warn({}, "User not identified, missing user details");
        return;
      }

      await analytics.identify(
        userStore?.currentUserBusinessLevel?.personal_email ?? "",
        {
          email: userStore?.currentUserBusinessLevel?.personal_email,
          firstName: userStore?.currentUserBusinessLevel?.first_name,
          lastName: userStore?.currentUserBusinessLevel?.last_name,
          phone: userStore?.currentUserBusinessLevel?.mobile,
        }
      );

      isIdentified.value = true;
    })();
  });

  watchEffect(() => {
    if (authStore.isAuthenticated && isIdentified.value) {
      return;
    }

    void (async () => {
      await analytics.reset();

      isIdentified.value = false;
    })();
  });
</script>
