<template>
  <v-card flat class="modal-card grey-dark text-left" width="500">
    <img
      v-if="!utilStore.displayFeedbackSurvey"
      class="pointer close-modal"
      src="@/assets/Images/close.svg"
      @click="utilStore.showPostOnboarding = false"
    />
    <img
      src="/assets/Images/engagements.webp"
      alt=""
      height="180%"
      width="100%"
      contain
      class="out-of-of-intro"
      draggable="false"
    />
    <div class="py-5 pa-6">
      <div v-if="!utilStore.displayFeedbackSurvey">
        <p class="add-modal-title mb-2 text-center">
          Tell us more about your business to allow us serve you better
        </p>
        <p class="set-up-success-subtitle text-center description">
          To tailor our services more closely to your needs, kindly provide some
          additional details about your business. It will only take a few
          minutes.
        </p>
      </div>
      <div v-else>
        <p class="add-modal-title mb-2 text-center">Help Us Serve You Better</p>
        <p class="set-up-success-subtitle text-center description">
          To tailor our services more closely to your needs, we'd love to know
          more about your business. This quick survey will only take a minute
          and helps us ensure you get the most out of PressOne.
        </p>
      </div>

      <div class="d-flex justify-center mt-10">
        <v-btn
          class="filled-primary-color"
          height="45"
          flat
          @click="handleSubmit"
        >
          {{
            !utilStore.displayFeedbackSurvey
              ? "Provide business details"
              : "Fill Survey"
          }}
        </v-btn>
      </div>
    </div>
  </v-card>
</template>
<script lang="ts" setup>
  import { useUtilsStore } from "@/stores/utils.store";
  import { useRouter } from "vue-router";

  defineEmits(["closeModal"]);

  const router = useRouter();
  const utilStore = useUtilsStore();

  const handleSubmit = async () => {
    if (!utilStore.displayFeedbackSurvey) {
      utilStore.isNewBusiness = false;
      utilStore.showPostOnboarding = false;
      await router.push("/managers/engagement");
    } else {
      utilStore.showPostOnboarding = false;
      utilStore.showFeedBackModal = true;
    }
  };
</script>
<style scoped>
  .close-modal {
    position: absolute;
    right: 16px;
    top: 17px;
  }
</style>
