import { useBusinessStore } from "@/stores/businesses.store";
import requestClient from "../api/requestClient";
import { BASE_API } from "../constants";
import { GreetingToSave } from "../types/personalization";

interface ResponseData {
  [key: string]: any;
}

const getActiveBusinessId = () => {
  const businessStore = useBusinessStore();
  return businessStore.currentBusinessId;
};
export const fetchOnboardingStage = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/onboarding-form/`
  );
};
export const updateOnboardingStage = async (payload: {
  [key: string]: boolean | null;
}): Promise<ResponseData> => {
  return await requestClient.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/onboarding-form/`,
    { json: payload }
  );
};
export const fetchProfessionalGreetings = async (
  type?: string,
  uuid?: string
): Promise<ResponseData> => {
  let url = `${BASE_API}/api/business/${getActiveBusinessId()}/voice-script/?page_size=60`;
  if (type) url += `&type=${type}`;
  if (uuid) url += `&uuid=${uuid}`;
  return await requestClient.get(url);
};

export const removeVoiceScript = async (id: string) => {
  return await requestClient.delete(`${BASE_API}/api/voice_scripts/?id=${id}`);
};

export const saveBasicGreeting = async (
  payload: GreetingToSave
): Promise<ResponseData> => {
  return await requestClient.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/request-greeting/`,
    { json: payload }
  );
};

export const fetchGreetingsAccent = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/request-greeting/voice-accent/`
  );
};
