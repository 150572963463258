import { BASE_API } from "../constants";
import requestClient from "../api/requestClient";

interface ResponseData {
  [key: string]: any;
}

export const getPendingNumberPayment = async (): Promise<ResponseData> => {
  return await requestClient.get(
    `${BASE_API}/api/payments/unprovisioned-payment/`
  );
};

// export const getUserDetailsByToken = async (): Promise<ResponseData> => {
//   return await fetchWrapper.get(`${BASE_API}/api/users/me/`);
// }; // already exist in queries.ts
