import { useSalesCampaignAddOnStore } from "@/stores/add-ons/sales-campaign.store";
import { useBusinessStore } from "@/stores/businesses.store";
import { useContactStore } from "@/stores/contact.store";
import { useDialerStore } from "@/stores/dialer.store";
import { useUsersStore } from "@/stores/users.store";
import { notify } from "@kyvg/vue3-notification";
import type { Ref } from "vue";
import { computed, ref } from "vue";
import { getSingleContact, handleSearchContacts } from "./contacts";
import { callNumber, endCall } from "./dialer";
import logger from "./logger";
import {
  getAgentCallQueueCampaign,
  getAgentCallQueueDetailsInCampaign,
  getAgentCallQueueItemsByCampaign,
  removeContactFromQueueCampaign,
} from "./queries/add-ons/sales-campaign";
import {
  addContactToQueue,
  // updateQueuePointer,
  fetchCallQueue,
  getAgentCallQueue,
  getAgentCallQueueItems,
  // getCallQueues,
  removeContactFromQueue,
  reorderCallQueueAgent,
} from "./queries/callQueue";
import type {
  AddContactToQueuePayload,
  QueueItemType,
  // CallQueueApiResponse,
  updateQueuePointerPayload,
} from "./types/callQueue";

export const useCallQueue = {
  callQueue: ref([]) as Ref<QueueItemType[]>,
  callQueueData: ref<any>(null),
  showMoreContact: ref<any>(""),
  showAddQueueModal: ref(false),

  showAddNewContactQueueModal: ref(false),

  callQueueCopy: ref([]) as Ref<QueueItemType[]>,

  activeContact: ref({}) as Ref<QueueItemType>,

  activeContactDetails: ref({}) as Ref<any>,

  activeContactAvatarColor: ref(""),

  nextActiveContact: ref({}) as Ref<QueueItemType>,

  callStateName: ref("Start"),

  isSearchingContact: ref(false),

  isFetching: ref(false),

  handleResponse: async (res: any) => {
    useCallQueue.callQueue.value = res || [];

    useCallQueue.callQueueCopy.value = [...useCallQueue.callQueue.value];
    useCallQueue.activeContact.value = useCallQueue.callQueue.value[0] || {};
    if (useCallQueue.callQueue.value.length) {
      // only run this when callqueue is not empty causing an undefined error in prod
      useCallQueue.activeContactDetails.value = await getSingleContact(
        useCallQueue.callQueue.value[0]?.contact_id
      );
    }

    useCallQueue.nextActiveContact.value =
      useCallQueue.callQueue.value[1] || {};

    if (useCallQueue.callQueue.value.length < 1) {
      useCallQueue.callStateName.value = "Start";
      const dialerStore = useDialerStore();
      dialerStore.isCallQueue = false;
    }
  },

  listCallQueue: async () => {
    try {
      useCallQueue.isFetching.value = true;
      const contactStore = useContactStore();

      const callQueueId = contactStore.callQueueId;

      const res = await fetchCallQueue(callQueueId).then(
        (res) => res.data?.data
      );
      useCallQueue.handleResponse(res);
      useCallQueue.isFetching.value = false;
    } catch (error) {
      useCallQueue.isFetching.value = false;
      logger.error(error, "listCallQueue");
    }
  },

  listCallQueueAgent: async () => {
    const businessStore = useBusinessStore();
    const contactStore = useContactStore();
    const salesCampaignAddonStore = useSalesCampaignAddOnStore();
    useCallQueue.isFetching.value = true;
    const hasAccessToCampaign = salesCampaignAddonStore.getAgentHasAccess;
    let response = [];
    if (
      !hasAccessToCampaign ||
      !salesCampaignAddonStore.currentAgentViewingCampaign
    ) {
      await getAgentCallQueue(businessStore.currentUserId).then((res) => {
        contactStore.callQueueId = res.data[0]?.id;
      });
      response = await getAgentCallQueueItems(
        businessStore.currentUserId,
        contactStore.callQueueId
      ).then((res) => {
        useCallQueue.callQueueData.value = res.data;

        return res.data?.data;
      });
    } else {
      await getAgentCallQueueCampaign(businessStore.currentUserId).then(
        (res) => {
          contactStore.callQueueId = res.data[0]?.id;
        }
      );
      const res = await getAgentCallQueueDetailsInCampaign(
        businessStore.currentUserId,
        contactStore.callQueueId,
        Number(salesCampaignAddonStore.currentAgentViewingCampaign?.id)
      );
      salesCampaignAddonStore.currentAgentViewingCallQueueDetails = res.data;
      response = await getAgentCallQueueItemsByCampaign(
        businessStore.currentUserId,
        contactStore.callQueueId,
        Number(salesCampaignAddonStore.currentAgentViewingCampaign?.id)
      ).then((res) => {
        useCallQueue.callQueueData.value = res.data;
        return res.data.data;
      });
    }

    useCallQueue.handleResponse(response);
    useCallQueue.isFetching.value = false;
  },
  loadMore: async () => {
    const businessStore = useBusinessStore();

    if (!useCallQueue.callQueueData?.value?.next) return;
    try {
      useCallQueue.showMoreContact.value = true;
      const callQueues = await getAgentCallQueue(
        businessStore.currentUserId,
        useCallQueue.callQueueData.value?.next
      );
      useCallQueue.callQueueData.value.next = callQueues?.data?.next;
      useCallQueue.callQueue.value = [
        ...useCallQueue.callQueue.value,
        ...callQueues.data.data,
      ];
      useCallQueue.callQueueCopy.value = useCallQueue.callQueue.value;
      useCallQueue.showMoreContact.value = false;
    } catch (error) {
      logger.error(error, "Load more queues");
    } finally {
      useCallQueue.showMoreContact.value = false;
    }
  },

  addContactToQueue: async (payload: AddContactToQueuePayload) => {
    try {
      const callQueueId = useContactStore().callQueueId;
      const userStore = useUsersStore();
      await addContactToQueue(payload, callQueueId);

      if (!userStore.canAccessManagerView) {
        await useCallQueue.listCallQueueAgent();
      } else {
        await useCallQueue.listCallQueue();
      }
    } catch (error: any) {
      notify({
        type: "error",
        text: error?.message || error?.detail || "An error occured",
      });
      logger.error(error, "addContactToQueue");
    }
  },

  removeFromCallQueue: async () => {
    const salesCampaignAddonStore = useSalesCampaignAddOnStore();
    try {
      const payload = {
        contacts: [useCallQueue.activeContact.value.contact_id],
      };
      const callQueueId = useContactStore().callQueueId;
      const businessStore = useBusinessStore();
      if (salesCampaignAddonStore.currentAgentViewingCampaign) {
        removeContactFromQueueCampaign(
          salesCampaignAddonStore.currentAgentViewingCampaign.id,
          callQueueId,
          useCallQueue.activeContact.value.id
        );
      }
      await removeContactFromQueue(
        payload,
        callQueueId,
        businessStore.currentUserId
      );

      await useCallQueue.listCallQueueAgent();
    } catch (error) {
      logger.error(error, "removeFromCallQueue");
    }
  },

  updateActiveContact: async () => {
    useCallQueue.activeContact.value = useCallQueue.callQueue.value[0] || {};

    useCallQueue.activeContactDetails.value = await getSingleContact(
      useCallQueue.callQueue.value[0].contact_id
    );

    useCallQueue.nextActiveContact.value =
      useCallQueue.callQueue.value[1] || {};
    useCallQueue.callQueueCopy.value = [...useCallQueue.callQueue.value];
  },

  callActiveContact: (pressoneInstance: any) => {
    if (
      useCallQueue.activeContact.value &&
      useCallQueue.callStateName.value === "Pause"
    ) {
      const dialerStore = useDialerStore();
      dialerStore.isCallQueue = true;

      if (useCallQueue.activeContact.value.number) {
        callNumber(useCallQueue.activeContact.value.number, pressoneInstance);
      }
    }
  },

  endQueueContactCall: async (pressoneInstance: any) => {
    endCall(pressoneInstance);
  },

  handleUpdateQueuePointer: async (payload: updateQueuePointerPayload) => {
    const contactStore = useContactStore();
    const callQueueId = contactStore.callQueueId;
    try {
      await reorderCallQueueAgent(payload, callQueueId);

      // await useCallQueue.listCallQueue();

      // useCallQueue.handleResponse(res);
    } catch (error: any) {
      notify({
        type: "error",
        text: error?.message || error?.detail || "An error occured",
      });
      logger.error(error, "handleUpdateQueuePointer");
    }
  },
  callQueueTotal: computed((): any => {
    return useCallQueue.callQueueData.value?.total;
  }),
  handleDragEnd: async (event: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = event;

    const payload = {
      action: newIndex > oldIndex ? "after" : "before",
      moved_contact_id: useCallQueue.callQueue.value[oldIndex].contact_id,
      cursor_contact_id: useCallQueue.callQueue.value[newIndex].contact_id,
    };

    await useCallQueue.handleUpdateQueuePointer(payload);

    useCallQueue.setUpdatedQueue(oldIndex, newIndex);
  },

  setUpdatedQueue: (oldIndex: number, newIndex: number) => {
    // Copy the callQueue.value array

    const updatedCallQueue = [...useCallQueue.callQueue.value];

    // Remove the element at the old index
    const [movedElement] = updatedCallQueue.splice(oldIndex, 1);

    // Adjust the newIndex if the oldIndex is before it
    const adjustedNewIndex = newIndex;

    // Insert the moved element at the adjusted newIndex
    updatedCallQueue.splice(adjustedNewIndex, 0, movedElement);
    useCallQueue.callQueue.value = updatedCallQueue;
    useCallQueue.callQueueCopy.value = updatedCallQueue;
    useCallQueue.updateActiveContact();
  },
};

export const getContactFromSavedContact = async (phoneNumber: string) => {
  useCallQueue.isSearchingContact.value = true;
  // const cleanedContactNumber = contact.contact_number.replace(
  //   /^(?:\+234|\+254|0)/,
  //   ""
  // );
  const cleanedPhoneNumber = phoneNumber.replace(/^(?:\+234|\+254|0)/, "0");
  const result = (await handleSearchContacts(cleanedPhoneNumber)).contacts;
  useCallQueue.isSearchingContact.value = false;
  return result;
  // return allContacts.find((contact) => {
  //   return cleanedContactNumber.includes(cleanedPhoneNumber);
  // });
};

export const getCallQueue = async () => {
  await useCallQueue.listCallQueue();
  return useCallQueue.callQueue.value;
};

export const isNumberValid = (number: string) => {
  const cleanedContactNumber = number.replace(/^(?:\+234|\+254|0)/, "");
  if (cleanedContactNumber.length == 10 && +cleanedContactNumber) {
    return true;
  } else {
    return false;
  }
};

// const arrangeContacts = (contacts: QueueItemType[]) => {
//   const arrangedContacts = [];
//   // Find the starting point in the linked list
//   let currentId = useCallQueue.head.value;
//   // Iterate through the linked list and arrange contacts
//   while (currentId !== null) {
//     const currentContact = contacts.find((contact) => contact.id === currentId);
//     if (currentContact) {
//       arrangedContacts.push(currentContact);
//       currentId = currentContact.next_contact;
//     } else {
//       break;
//     }
//   }
//   return arrangedContacts;
// };

export const cleanPayload = (obj: any) => {
  //function to remove object properties with null as keys
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getCallQueueItems = async () => {
  const callQueueId = useContactStore().callQueueId;
  return await fetchCallQueue(callQueueId).then((res) => res.data);
};

export const getCallQueueItemsForTeamMember = async (
  id?: any,
  url?: string
) => {
  if (url) {
    return await fetchCallQueue(undefined, url).then((res) => res.data);
  }
  return await fetchCallQueue(id).then((res) => res.data);
};

export const addContactsToTeamMemberCallQueue = async (
  callQueueId: string,
  payload: any,
  initiator?: string
) => {
  try {
    await addContactToQueue(payload, callQueueId);
    if (initiator != "fromContactsQueue") {
      return await getCallQueueItemsForTeamMember(callQueueId);
    }
  } catch (error: any) {
    notify({
      type: "error",
      text: error?.message || error?.detail || "An error occured",
    });
  }
};

export function distributeContactsEvenly(total: number, length: number) {
  const result = new Array(length).fill(0); // Create an array of the given length, initialized with zeros
  const baseValue = Math.floor(total / length); // Determine the base value for each item
  const remainder = total % length; // Calculate the remainder

  // Distribute the base value
  for (let i = 0; i < length; i++) {
    result[i] = baseValue;
  }

  // Distribute the remainder
  for (let i = 0; i < remainder; i++) {
    result[i]++;
  }

  return result;
}
