import OpenReplay from "@openreplay/tracker";
import { IS_PROD } from "./constants";

const openReplay = new OpenReplay({
  projectKey: import.meta.env.VITE_OPEN_REPLAY_KEY,
  ingestPoint: "https://openreplay.pressone.co/ingest",
  network: {
    capturePayload: true, // Capture HTTP payload
    captureErrorPayload: true,
    ignoreHeaders: false,
  } as any,
  __DISABLE_SECURE_MODE: !IS_PROD,
});

export default openReplay;
