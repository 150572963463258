<template>
  <v-card flat class="modal-card grey-dark py-2 px-0">
    <div class="d-flex align-center justify-space-between px-6 py-3">
      <p class="logout-title">
        <span>Logging Out...</span>
      </p>

      <v-icon class="pointer" color="#475367" @click="toggleLogout"
        >mdi mdi-close</v-icon
      >
    </div>

    <v-divider></v-divider>
    <p class="logout-desc px-6 pt-4 pb-5">
      Are you sure you want to log out of your PressOne Agent account? You will
      need to login to regain access.
    </p>
    <div class="d-flex justify-end px-5 pb-3">
      <ButtonVue
        v-if="!authStore.isLoggingOut"
        title="No, cancel"
        box-shadow="none"
        button-color="transparent"
        text-color="text-primary cancel-btn"
        @click="toggleLogout"
      />
      <router-link to="/sign-in">
        <ButtonVue
          title="Yes, log me out"
          box-shadow="0px 1px 1px 0px #FF3B30"
          button-color="#FF3B30"
          text-color="text-white"
          class="logout-btn"
          :is-loading="authStore.isLoggingOut"
          :disabled="authStore.isLoggingOut"
          @click="logout"
        />
      </router-link>
    </div>
  </v-card>
</template>
<script lang="ts">
  import requestClient from "@/helpers/api/requestClient";
  import logger from "@/helpers/logger";
  import { PressoneInstance } from "@/plugins/dialer";
  import { useAuthStore } from "@/stores/auth.store";
  import { useDialerStore } from "@/stores/dialer.store";
  import { defineComponent, inject } from "vue";
  import { useRouter } from "vue-router";
  import ButtonVue from "./Button.vue";

  export default defineComponent({
    components: { ButtonVue },
    emits: ["toggleLogout"],
    setup(_, { emit }) {
      const authStore = useAuthStore();
      const dialerStore = useDialerStore();

      const router = useRouter();

      const getPressoneInstance: PressoneInstance | undefined = inject(
        "getPressoneInstance"
      );
      const toggleLogout = () => {
        emit("toggleLogout");
      };
      const logout = async () => {
        try {
          authStore.isLoggingOut = true;
          await authStore.CLEAR_X_REFRESH_TOKEN();
          await authStore.logout();

          requestClient.abortRequest();
          // stop all user agents connection on logout
          const instanceKeys = Object?.keys(dialerStore.sipCredentials || {});

          if (instanceKeys.length) {
            void getPressoneInstance!()?.stop(instanceKeys);
          }

          await router.push({ name: "Signin" });
        } catch (error) {
          logger.error(error, "Error logging out");
        } finally {
          authStore.isLoggingOut = false;
        }
      };

      return {
        toggleLogout,
        logout,
        authStore,
      };
    },
  });
</script>
